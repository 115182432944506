import { ApiService } from '@/app/services/index';

export const RulesService = {
  getRules() {
    return ApiService.get('/rules/list');
  },
  setRule(data) {
    return ApiService.patch('/rules/set', data);
  },
  listSystem(data) {
    return ApiService.get('/data/vsphere/system', data);
  },
};
