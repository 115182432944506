import Vue from 'vue';
import App from '@/app/ui/App.vue';
import router from '@/app/router';
import {
  createPinia,
  PiniaVuePlugin,
} from 'pinia';
import VueCompositionAPI from '@vue/composition-api';
import VueEasytable from 'vue-easytable';
import i18n from '@/i18n';
import storage from 'store2';
import PortalVue from 'portal-vue';
import Notifications from 'vue-notification';
import VueApexCharts from 'vue-apexcharts';

import { promiseHelper } from '@/app/helpers/promiseHelper';
import api from '@/app/plugins/api';

import '@/app/assets/css/tailwind.css';
import '@/app/assets/css/vue-easytable.less';

Vue.config.productionTip = false;

Vue.use(PiniaVuePlugin);
Vue.use(VueCompositionAPI);
Vue.use(PortalVue);
Vue.use(Notifications);
Vue.use(api);
Vue.use(VueEasytable);
Vue.use(VueApexCharts);
Vue.component('ApexChart', VueApexCharts);

const pinia = createPinia();

Vue.$i18n = i18n;
Vue.$promiseHelper = promiseHelper;
Vue.$storage = storage;
Vue.prototype.$storage = storage;
Vue.prototype.$appVersion = '1.1.0';

new Vue({
  router,
  pinia,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
