<template>
  <div
    @mouseenter="toggle = true"
    @mouseleave="toggle = false"
  >
    <slot
      name="activator"
      :toggle="toggle"
    />
    <div
      class="absolute transition-opacity text-xs"
      :class="[
        contentClass,
        toggle ? 'visible opacity-100' : 'invisible opacity-0',
      ]"
    >
      <slot :toggle="toggle" />
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';

export default {
  props: {
    contentClass: { type: String, default: 'top-0 right-0' },
  },
  setup() {
    const toggle = ref(false);

    return {
      toggle,
    };
  },
};
</script>
