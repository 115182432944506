import $i18n from '@/i18n';

export default {
  activeTab: (state) => {
    return state.tabs.find((el) => el.selected).value;
  },
  responseIncludesSelected: (state) => {
    const activeTab = state.tabs.find((el) => el.selected);

    return state.responseIncludes.reduce((total, el) => {
      if (el.includedIn.includes(activeTab.value) && el.selected) {
        total.push(el.value);
      }

      return total;
    }, []);
  },
  responseIncludesByTab: (state) => {
    const activeTab = state.tabs.find((el) => el.selected);
    const items = state.responseIncludes.filter((el) => el.includedIn.includes(activeTab.value));

    return items.sort((a, b) => a.label.localeCompare(b.label));
  },
  clusterAlertsGroups: (state) => {
    const alerts = state.alerts.cluster;
    const amount = Object.keys(alerts).reduce((total, key) => total += alerts[key].length, 0);
    const data = Object.keys(alerts).map((key) => {

      return {
        name: key,
        label: $i18n.t('object-alerts.' + key),
        items: alerts[key],
      };
    });

    return {
      data,
      amount,
    };
  },
  hostAlertsGroups: (state) => {
    const alerts = state.alerts.host;
    const amount = Object.keys(alerts).reduce((total, key) => total += alerts[key].length, 0);
    const data = Object.keys(alerts).map((key) => {

      return {
        name: key,
        label: $i18n.t('object-alerts.' + key),
        items: alerts[key],
      };
    });

    return {
      data,
      amount,
    };
  },
  vmAlertsGroups: (state) => {
    const alerts = state.alerts.vm;
    const amount = Object.keys(alerts).reduce((total, key) => total += alerts[key].length, 0);
    const data = Object.keys(alerts).map((key) => {

      return {
        name: key,
        label: $i18n.t('object-alerts.' + key),
        items: alerts[key],
      };
    });

    return {
      data,
      amount,
    };
  },
  groupAlerts: (state) => {
    const groupName = state.alerts.expandedGroup.name;
    const sectionType = state.alerts.expandedSection.type;

    if (!groupName && !sectionType) {
      return null;
    }

    return state.alerts[sectionType][groupName];
  },
};

