import { ApiService } from '@/app/services/index';

export const MailService = {
  getMailSettings() {
    return ApiService.get('/settings/mail/get');
  },
  updateMailSettings(data) {
    return ApiService.put('/settings/mail/update', data);
  },
  deleteMailSettings() {
    return ApiService.delete('/settings/mail/delete');
  },
  testMailSettings() {
    return ApiService.post('/settings/mail/test');
  },
};
