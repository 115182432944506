<template>
  <nav class="the-sidebar-nav | flex flex-col">
    <router-link
      v-for="item in menuItems"
      :key="item.key"
      :to="item.path"
      class="the-sidebar-nav__link | flex items-center justify-between text-grey-light leading-3.5 py-4 rounded-md relative"
    >
      <span class="the-sidebar-nav__link-name | leading-none truncate w-0 opacity-0 transition-all">
        {{ $t(`${item.name}`) }}
      </span>
      <component
        :is="getIcon(item)"
        :size="20"
        class="the-sidebar-nav__link-icon | pr-4 mr-0.5 transition-all"
      />
    </router-link>
  </nav>
</template>

<script>
import { ref } from '@vue/composition-api';
import ViewDashboardOutlineIcon from 'vue-material-design-icons/ViewDashboardOutline.vue';
import BellIcon from 'vue-material-design-icons/Bell.vue';
import ContentPasteIcon from 'vue-material-design-icons/ContentPaste.vue';
import CogOutlineIcon from 'vue-material-design-icons/CogOutline.vue';
import PowerIcon from 'vue-material-design-icons/Power.vue';
import PencilRulerIcon from 'vue-material-design-icons/PencilRuler.vue';

export default {
  components: {
    ViewDashboardOutlineIcon,
    BellIcon,
    ContentPasteIcon,
    CogOutlineIcon,
    PowerIcon,
    PencilRulerIcon,
  },
  setup(props, { root: { _router } }) {
    const routes = _router.options.routes.filter((item) =>
      item.props?.mainMenu
      && item.name !== 'reports', // temporary hide reports
    );
    const menuItems = ref(routes);

    function getIcon(item) {
      switch(item.name) {
      case 'dashboard':
        return 'ViewDashboardOutlineIcon';
      case 'rules':
        return 'PencilRulerIcon';
      case 'reports':
        return 'ContentPasteIcon';
      case 'settings':
        return 'CogOutlineIcon';
      default:
        return 'PowerIcon';
      }
    }

    return {
      menuItems,
      getIcon,
    };
  },
};
</script>

<style lang="scss" scoped>
.the-sidebar:hover {

  .the-sidebar-nav {

    &__link {
      justify-content: space-between;

      &-name {
        opacity: 1;
        width: auto;
      }

      &-icon {
        padding-right: 0;
      }
    }
  }
}

.router-link-exact-active > .the-sidebar-nav__link-icon {
  color: var(--secondary);
}
</style>
