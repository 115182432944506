export function downloadHelper(data, name) {
  const response = data;
  const disposition = response.headers['content-disposition'];
  const startFileNameIndex = disposition.indexOf('"') + 1;
  const endFileNameIndex = disposition.lastIndexOf('"');
  const filename = name ? name : disposition.substring(startFileNameIndex, endFileNameIndex);
  const a = document.createElement('a');

  a.href = URL.createObjectURL(response.data);
  a.download = decodeURIComponent(filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}
