<template>
  <fieldset class="base-checkbox flex items-center relative">
    <input
      type="checkbox"
      class="hidden"
      :disabled="disabled"
      :checked="checked"
    >
    <label class="h-5 w-5 mr-1.5 relative cursor-pointer" />
    <slot />
  </fieldset>
</template>

<script>
export default {
  props: {
    checked: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.base-checkbox label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-radius: 4px;
}

.base-checkbox label::after {
  display: none;
  content: "";
  width: 10px;
  height: 5px;
  border-bottom: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  top: 6px;
  left: 4px;
}

.base-checkbox input[type="checkbox"]:checked ~ label::after {
  display: block;
  transform: rotate(-45deg) scale(1);
}
</style>
