export const promiseHelper = (promise) => {
  return promise
    .then(
      (response) => {
        return { response };
      },
      (error) => {
        return { error };
      },
    );
};

