<template>
  <div>
    <div
      v-for="item in items"
      :key="item.key"
      class="flex gap-1 items-center mb-2"
    >
      <span
        class="text-xs text-primary-dark w-24 rounded-md"
        :class="[getBgColor(item.title), { 'p-1 px-3': item.oldValue }]"
      >
        <span class="font-bold">{{ item.oldValue }}</span>
        <span v-if="item.oldValue">{{ getTypeAbbreviation(item.title) }}</span>
      </span>
      <span class="w-4 mr-1">
        <ArrowRightIcon
          v-if="arrowsOn"
          :size="20"
        />
      </span>
      <span
        class="text-xs text-primary-dark w-24 rounded-md"
        :class="[getBgColor(item.title), { 'p-1 px-3': item.newValue }]"
      >
        <span class="font-bold">{{ item.newValue }}</span>
        <span v-if="item.newValue">{{ getTypeAbbreviation(item.title) }}</span>
      </span>
      <span class="flex items-center pl-7">
        <CheckboxBlankCircleIcon
          :size="10"
          :class="getTextColor(item.title)"
        />
        <span class="pl-2 text-xs">{{ $t(`modal-summary.${ item.title }`) }}</span>
      </span>
    </div>
  </div>
</template>

<script>
import CheckboxBlankCircleIcon from 'vue-material-design-icons/CheckboxBlankCircle.vue';
import ArrowRightIcon from 'vue-material-design-icons/ArrowRight.vue';

export default {
  components: {
    ArrowRightIcon,
    CheckboxBlankCircleIcon,
  },
  props: {
    items: { type: Array, required: true },
    arrowsOn: { type: Boolean, default: false },
  },
  setup() {
    function getTextColor(type) {
      switch(type) {
      case 'hostDistributedPortgroupSummary':
      case 'coresSummary':
        return 'text-secondary-light';
      case 'hostPortgroupSummary':
      case 'socketsSummary':
        return 'text-tertiary-blue';
      case 'networksSummary':
        return 'text-tertiary-yellow';
      case 'vmsClonedSummary':
      case 'cpuSpeedSummary':
        return 'text-accent-light';
      case 'vmsSummary':
      case 'memorySummary':
        return 'text-primary-light';
      default:
        return 'text-primary-light';
      }
    }

    function getBgColor(type) {
      switch(type) {
      case 'hostDistributedPortgroupSummary':
      case 'coresSummary':
        return 'bg-secondary-light';
      case 'hostPortgroupSummary':
      case 'socketsSummary':
        return 'bg-tertiary-blue';
      case 'networksSummary':
        return 'bg-tertiary-yellow';
      case 'vmsClonedSummary':
      case 'cpuSpeedSummary':
        return 'bg-accent-light';
      case 'vmsSummary':
      case 'memorySummary':
        return 'bg-primary-light';
      default:
        return 'bg-primary-light';
      }
    }

    function getTypeAbbreviation(type) {
      switch(type) {
      case 'hostDistributedPortgroupSummary':
        return ' DHP';
      case 'hostPortgroupSummary':
        return ' HP';
      case 'vmsSummary':
      case 'vmsClonedSummary':
        return ' VMs';
      case 'networksSummary':
        return ' Network';
      case 'memorySummary':
        return ' GB';
      case 'cpuSpeedSummary':
        return ' Ghz';
      default:
        return '';
      }
    }

    return {
      getTextColor,
      getTypeAbbreviation,
      getBgColor,
    };
  },
};
</script>
