import { ApiService } from '@/app/services/index';

export const AuthService = {
  signIn(data) {
    return ApiService.post('/auth/token/create', data);
  },
  refreshToken(data) {
    return ApiService.post('/auth/token/refresh', data);
  },
};
