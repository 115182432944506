<template>
  <div>
    <ChevronUpIcon
      v-if="show"
      :size="size"
    />
    <ChevronDownIcon
      v-else
      :size="size"
    />
  </div>
</template>

<script>
import ChevronUpIcon from 'vue-material-design-icons/ChevronUp.vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';

export default {
  components: {
    ChevronUpIcon,
    ChevronDownIcon,
  },
  props: {
    show: { type: Boolean, default: false },
    size: { type: Number, default: 18 },
  },
};
</script>
