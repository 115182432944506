<template>
  <div class="flex py-4">
    <div class="flex flex-1 flex-wrap justify-between">
      <div
        v-for="chart in charts"
        :key="chart.key"
        class="flex"
      >
        <div class="w-28">
          <ApexChart
            class="-mt-5 -ml-10"
            :options="options"
            :series="[chart.percentage]"
          />
        </div>
        <div class="-ml-9">
          <h4 class="text-xs mb-1.5">
            {{ $t('storage') }}
          </h4>
          <p class="text-xs mb-1.5">
            {{ chart.name }}
          </p>
          <p class="text-xs mb-1.5">
            <strong class="text-secondary">
              {{ chart.value }}
            </strong>
            {{ $t('of') }}
            <strong>
              {{ chart.total }}
            </strong>
            GB {{ $t('used') }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="storageLeft < 50"
      class="flex items-center w-60 text-xs h-16 ml-8"
    >
      <p
        class="flex text-xs font-bold mb-2"
        :class="{ 'text-accent-light': storageLeft < 25 }"
      >
        <component
          :is="icon"
          class="pr-2"
          :size="16"
        />
        {{ $t('you-have-less-than-of-storage-left', { val: `${ storageLeft }%` }) }}.
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import AlertCircleOutlineIcon from 'vue-material-design-icons/AlertCircleOutline.vue';
import BellOutlineIcon from 'vue-material-design-icons/BellOutline.vue';

export default {
  components: {
    AlertCircleOutlineIcon,
    BellOutlineIcon,
  },
  props: {
    data: { type: Array, required: true },
  },
  setup(props) {
    const charts = computed(() => {
      return props.data.map((el) => {
        const percentage = el.value / el.total * 100;

        return {
          percentage: Math.floor(percentage),
          ...el,
        };
      });
    });

    const storageLeft = computed(() => {
      const totalUsed = charts.value.reduce((total, el) => total + Number(el.percentage), 0);
      const used = totalUsed / charts.value.length;
      const left = 100 - used;

      return left;
    });

    const icon = computed(() => {
      if (storageLeft < 25) {
        return BellOutlineIcon;
      }

      return AlertCircleOutlineIcon;
    });

    const options = {
      chart: {
        type: 'radialBar',
      },
      stroke: {
        lineCap: 'round',
      },
      colors: ['var(--secondary)'],
      plotOptions: {
        radialBar: {
          track: {
            background: 'var(--primary-dark)',
          },
          hollow: {
            margin: 15,
            size: '30%',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: true,
              color: 'var(--light)',
              offsetY: 3,
              fontSize: 'var(--s3)',
            },
          },
        },
      },
    };

    return {
      icon,
      charts,
      options,
      storageLeft,
    };
  },
};
</script>
