import { ApiService } from '@/app/services/index';

export const SupportChatService = {
  getSupportChatStatus() {
    return ApiService.get('/chatsupport/get');
  },
  enableSupportChat() {
    return ApiService.post('/chatsupport/enable');
  },
  disableSupportChat() {
    return ApiService.post('/chatsupport/disable');
  },
};
