<template>
  <div>
    <div class="flex justify-between mt-2.5 mb-5">
      <div class="flex items-center font-bold">
        <span
          class="flex items-center cursor-pointer"
          @click="$emit('goBack', section.type)"
        >
          <ChevronLeftIcon class="mr-4" />
          <component
            :is="section.icon"
            :size="20"
            class="pr-2"
          />
          <span>{{ section.name }}</span>
        </span>
        <ChevronRightIcon
          class="px-1"
          :size="16"
        />
        <span class="text-secondary">{{ group.label }}</span>
      </div>
      <div>
        <span class="bg-secondary text-xs text-primary-dark px-4 py-1 rounded-md font-light">
          {{ $t('oldest-alert') }}: {{ latestAlertTime }}
        </span>
      </div>
    </div>
    <ve-table
      class="virtual-table mb-8"
      :max-height="595"
      :virtual-scroll-option="{
        enable: isTableVirtual,
        scrolling: scrollingTable
      }"
      :columns="columns"
      :table-data="data"
      row-key-field-name="rowKey"
    />
    <p
      class="text-secondary text-xs font-bold"
      :class="{ 'hidden': !isTableVirtual }"
    >
      {{ tableStartIndex }}-{{ tableEndIndex - 2 }} out of {{ data.length }} alerts
    </p>
  </div>
</template>

<script>
import {
  ref,
  computed,
} from '@vue/composition-api';
import ChevronLeftIcon from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import CheckboxMarkedCircleOutlineIcon from 'vue-material-design-icons/CheckboxMarkedCircleOutline.vue';
import CheckboxBlankCircleOutlineIcon from 'vue-material-design-icons/CheckboxBlankCircleOutline.vue';

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    section: { type: Object, default: () => ({}) },
    group: { type: Object, default: () => ({}) },
    data: { type: Array, default: () => [] },
  },
  setup(props, { root: { $i18n }, emit }) {
    // Constants
    const isTableVirtual = computed(() => props.data.length > 10);
    const tableStartIndex = ref(0);
    const tableEndIndex = ref(0);
    const latestAlertTime = computed(() => {
      const item = props.data.reduce((a, b) => (a.time > b.time ? a : b));

      return new Date(item.time).toUTCString();
    });

    // Show scrolling progress
    function scrollingTable({visibleStartIndex, visibleEndIndex}) {
      tableStartIndex.value = visibleStartIndex;
      tableEndIndex.value = visibleEndIndex;
    }

    // Table columns
    const columns = [
      {
        field: 'name',
        title: $i18n.t('name'),
        key: 'name',
        align: 'center',
      },
      {
        field: 'time',
        title: $i18n.t('time'),
        key: 'time',
        align: 'center',
        renderBodyCell: ({ row: { time } }) => new Date(time).toUTCString(),
      },
      {
        field: 'description',
        title: $i18n.t('description'),
        key: 'description',
        align: 'center',
      },
      {
        field: 'acknowledge',
        title: $i18n.t('acknowledge'),
        key: 'acknowledge',
        align: 'center',
        renderBodyCell: ({ row }, h) => {
          const icon = row.acknowledged ? CheckboxMarkedCircleOutlineIcon : CheckboxBlankCircleOutlineIcon;

          return h(
            icon, {
              class: 'inline-block cursor-pointer',
              attrs: { size: 16 },
              on: {
                click: () => {
                  if (row.acknowledged) {
                    return;
                  }
                  row.acknowledged = true;
                  emit('acknowledge', row.id);
                },
              },
            },
          );
        },
      },
    ];

    return {
      columns,
      tableEndIndex,
      isTableVirtual,
      tableStartIndex,
      latestAlertTime,
      scrollingTable,
    };
  },
};
</script>

<style lang="postcss">
.virtual-table.ve-table .ve-table-container table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th {
  @apply bg-primary-dark;
}
.virtual-table.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body.ve-table-row-hover tr.ve-table-body-tr:hover td {
  @apply bg-transparent;
}
.virtual-table.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body.ve-table-row-hover tr.ve-table-body-tr td {
  @apply text-xs;
}
</style>
