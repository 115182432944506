import { ApiService } from '@/app/services';
import { objectToQueryString } from '@/app/helpers/queryHelper';

export const ReportsServices = {
  vm(payload, config) {
    return ApiService.get(`/data/vsphere/vm/changes/pdf${ objectToQueryString(payload) }`,
      { timeout: 60 * 1000 * 50, responseType: 'blob', ...config },
    );
  },
  host(payload, config) {
    return ApiService.get(`/data/vsphere/host/changes/pdf${ objectToQueryString(payload) }`,
      { timeout: 60 * 1000 * 50, responseType: 'blob', ...config });
  },
  cluster(payload, config) {
    return ApiService.get(`/data/vsphere/cluster/changes/pdf${ objectToQueryString(payload) }`,
      { timeout: 60 * 1000 * 50, responseType: 'blob', ...config },
    );
  },
};
