<template>
  <div>
    <div class="grid grid-cols-3 gap-7">
      <p class="text-xs">
        {{ $t('report-summary-section') }}
      </p>
      <div class="grid grid-cols-3 gap-y-2 gap-x-4 col-span-2">
        <BaseCheckbox
          v-for="(checkbox, index) in reportModalState.checkboxes"
          :key="checkbox.text"
          :checked="checkbox.checked"
          :class="{ 'col-span-3 mx-auto': index === reportModalState.checkboxes.length - 1 }"
          @click.native="checkbox.checked = !checkbox.checked"
        >
          <span class="text-xs">{{ checkbox.text }}</span>
        </BaseCheckbox>
      </div>
    </div>
    <div class="grid justify-center mt-4 gap-y-4">
      <div v-if="pageSizeError">
        <p class="text-xs text-accent-light flex items-center">
          <InformationIcon
            class="mr-2"
            :size="12"
          />
          {{ $t('report-page-size-error-message', { val: 20 }) }}
        </p>
        <div class="flex items-center justify-center mt-3 gap-4">
          <BaseButton
            :text="$t('continue')"
            :loading="isLoading"
            text-small
            @click.native="createReportHandler(true)"
          />
          <BaseButton
            :text="$t('cancel')"
            text-small
            @click.native="$emit('cancel')"
          />
        </div>
      </div>
      <BaseButton
        v-if="!pageSizeError"
        :text="isLoading ? $t('creating-a-report') : $t('create-a-report')"
        text-small
        :loading="isLoading"
        @click.native="createReportHandler(false)"
      />
      <p
        v-if="createReportSuccess"
        class="text-primary-light text-xs font-bold flex items-center"
      >
        <CheckIcon
          class="mr-2"
          :size="12"
        /> {{ $t('report-success-message') }}
      </p>
      <p
        v-if="createReportError"
        class="text-accent-light text-xs font-bold flex items-center"
      >
        <CloseIcon
          class="mr-2"
          :size="12"
        /> {{ $t('report-error-message') }}
      </p>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import InformationIcon from 'vue-material-design-icons/Information.vue';
import {
  reactive,
} from '@vue/composition-api';
import BaseButton from '@/app/ui/BaseButton.vue';
import BaseCheckbox from '@/app/ui/BaseCheckbox.vue';
import { useDashboardStore } from '@/dashboard/store';
import { CLEAR_REPORT_ERRORS } from '@/dashboard/store/constants';

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    CheckIcon,
    CloseIcon,
    InformationIcon,
  },
  props: { isLoading: { type: Boolean, default: false } },
  setup(props, { root: { $i18n } }) {
    // Store
    const store = useDashboardStore();
    const clearReportErrors = store[CLEAR_REPORT_ERRORS];
    const { createReportError, createReportSuccess, pageSizeError  } = storeToRefs(store);

    const reportModalState = reactive({
      showSuccess: false,
      showError: false,
      checkboxes: [
        { text: $i18n.t('clusters'), checked: true, value: 'CLUSTER' },
        { text: $i18n.t('hosts'), checked: true, value: 'HOST' },
        { text: $i18n.t('vms'), checked: true, value: 'VM' },
        { text: $i18n.t('add-detailed-messages'), checked: true, value: 'MESSAGES' },
      ],
    });

    async function createReportHandler(force = false) {
      clearReportErrors();

      const payload = {
        summaryResponseIncludes: reportModalState.checkboxes
          .filter((checkbox) => checkbox.checked)
          .map((checkbox) => checkbox.value),
      };

      if (force) {
        this.$emit('create-force-report', payload);

        return;
      }

      this.$emit('create-report', payload);
    }

    return {
      createReportError,
      createReportSuccess,
      pageSizeError,
      reportModalState,
      createReportHandler,
    };
  },
};
</script>
