<template>
  <button
    class="btn flex items-center justify-center gap-2 px-7.5 relative transition"
    :class="cssClasses"
    :disabled="loading || disable"
  >
    <BaseLoader
      v-if="loading"
      :loading="loading"
      class="h-5 w-5"
    />
    <slot v-else />
    <span
      class="block"
      :class="[textSmall ? 'text-h6' : 'text-h4']"
    >
      {{ text }}
    </span>
  </button>
</template>

<script>
import { computed } from '@vue/composition-api';
import BaseLoader from '@/app/ui/BaseLoader.vue';

export default {
  components: {
    BaseLoader,
  },
  props: {
    loading: { type: Boolean, default: false },
    disable: { type: Boolean, default: false },
    text: { type: String, default: '' },
    widthFull: { type: Boolean, default: false },
    textSmall: { type: Boolean, default: false },
    secondary: { type: Boolean, default: false },
  },
  setup(props) {
    const cssClasses = computed(() => {
      return {
        'cursor-auto hover:bg-primary-dark': props.loading,
        'hover:bg-grey-dark bg-grey-dark text-primary-light': props.disable,
        'w-full': props.widthFull,
        'text-white': !props.secondary,
        'bg-grey text-primary-dark': props.secondary,
      };
    });

    return { cssClasses };
  },
};
</script>
