<template>
  <div
    class="bg-primary-dark text-light p-4 text-center rounded shadow transition"
    :class="cssClasses"
  >
    <h3
      class="h4 flex justify-center mb-4"
      :class="{ 'text-accent-light': pieChart.type === 'alerts' }"
    >
      <component
        :is="icon"
        :size="20"
        class="pr-4"
      />
      {{ pieChart.title }}
    </h3>
    <div :class="{ 'flex flex-wrap': pieChart.data.length > 3 }">
      <div
        v-for="(item, i) in pieChart.data"
        :key="item.key"
        class="chart mb-4"
        :class="[
          { 'w-6/12': pieChart.data.length > 3 },
          { 'w-full': i === 0 && pieChart.data.length > 4 }
        ]"
      >
        <h4 :class="item.colorClass">
          {{ item.name }}
        </h4>
        <p>
          <span :class="{ 'text-accent-light': pieChart.type === 'alerts' }">
            {{ item.value }}
          </span>
          <span class="text-xs">
            {{ $t('out-of') }}
          </span>
          <span :class="{ 'text-accent-light': pieChart.type === 'alerts' }">
            {{ item.total }}
          </span>
        </p>
        <div class="w-28 m-auto">
          <ApexChart
            :options="item.options"
            :series="[item.value, item.total - item.value]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import BellOutlineIcon from 'vue-material-design-icons/BellOutline.vue';
import GoogleCirclesExtendedIcon from 'vue-material-design-icons/GoogleCirclesExtended.vue';
import ServerIcon from 'vue-material-design-icons/Server.vue';
import CubeScanIcon from 'vue-material-design-icons/CubeScan.vue';

export default {
  components: {
    ServerIcon,
    CubeScanIcon,
    BellOutlineIcon,
    GoogleCirclesExtendedIcon,
  },
  props: {
    chart: { type: Object, required: true },
  },
  setup(props) {

    const { chart } = props;

    // COMPUTED PROPS
    const pieChart = computed(() => {
      const item = { ...chart };

      item.data.forEach((el) => {
        el.colorClass = item.type === 'alerts' ? 'text-accent-light' : getColorClass(el.type);
        el.options = getOptions(el.type);
      });

      return item;
    });

    const cssClasses = computed(() => {
      return {
        'w-40': chart.size === 'small',
        'w-64': chart.size === 'medium',
        'w-80': chart.size === 'large',
      };
    });

    const icon = computed(() => {
      switch(chart.type) {
      case 'clusters':
        return GoogleCirclesExtendedIcon;
      case 'hosts':
        return ServerIcon;
      case 'vms':
        return CubeScanIcon;
      default:
        return BellOutlineIcon;
      }
    });

    // Get color class
    function getColorClass(type) {
      switch(type) {
      case 'vms':
        return 'text-primary-light';
      case 'hosts':
        return 'text-tertiary-pink';
      case 'cpu-sockets':
        return 'text-tertiary-blue';
      case 'cpu-cores':
        return 'text-secondary-light';
      case 'memory':
        return 'text-primary-light';
      case 'network':
        return 'text-secondary';
      default:
        return 'text-accent-light';
      }
    }

    // Get options for charts
    function getOptions(type) {
      const colors = ['', 'var(--grey-dark)'];
      const options = {
        chart: {
          type: 'pie',
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        tooltip: {
          enabled: false,
        },
      };

      switch(type) {
      case 'vms':
        colors[0] = 'var(--primary-light)';
        break;
      case 'hosts':
        colors[0] = 'var(--tertiary-pink)';
        break;
      case 'cpu-sockets':
        colors[0] = 'var(--tertiary-blue)';
        break;
      case 'cpu-cores':
        colors[0] = 'var(--secondary-light)';
        break;
      case 'memory':
        colors[0] = 'var(--primary-light)';
        break;
      case 'network':
        colors[0] = 'var(--secondary)';
        break;
      default:
        colors[0] = 'var(--accent-light)';
      }

      return { ...options, colors };
    }

    return {
      icon,
      pieChart,
      cssClasses,
    };
  },
};
</script>
