// Get a date before spicific amount of days
export function getDateBefore(amountOfDays) {
  const today = new Date();

  return new Date(today.setDate(today.getDate() - amountOfDays));
}

// Get difference between two dates
export function getDiffInDays(firstDate, secondDate) {
  const ms = 1000 * 60 * 60 * 24;
  const firstDateUtc = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
  const secondDateUtc = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());

  return Math.floor((secondDateUtc - firstDateUtc) / ms);
}
