<template>
  <div class="relative">
    <AlertCircleOutlineIcon
      :size="16"
      @mouseenter.native="tooltipDisplayClass = ''"
      @mouseleave.native="tooltipDisplayClass = 'hidden'"
    />
    <div
      class="absolute bg-primary-darker right-4 top-4 p-4 z-10 rounded-lg w-60 text-left flex gap-2"
      :class="[tooltipDisplayClass]"
    >
      <AlertCircleOutlineIcon :size="16" />
      <span class="flex flex-col gap-1 text-light text-xs">{{ formattedMessage }}</span>
    </div>
  </div>
</template>

<script>
import {
  computed,
  ref,
} from '@vue/composition-api';
import AlertCircleOutlineIcon from 'vue-material-design-icons/AlertCircleOutline.vue';

export default {
  name: 'DashboardCardTooltip',
  components: {
    AlertCircleOutlineIcon,
  },
  props: {
    message: { type: String, required: true },
    extended: { type: Boolean, default: false },
  },
  setup(props) {
    let tooltipDisplayClass = ref('hidden');
    const formattedMessage = computed(() => {
      const parsedMessage = JSON.parse(props.message);

      return Object.keys(parsedMessage).reduce((previous, key) =>{
        return `${ previous }${ key }: ${ parsedMessage[key] }\n\n`;
      }, '').trim();
    });

    return {
      tooltipDisplayClass,
      formattedMessage,
    };
  },
};
</script>
