import Vue from 'vue';
import {
  GET_VCENTERS,
  GET_CLUSTER_CHANGES,
  GET_HOST_CHANGES,
  GET_VM_CHANGES,
  GET_CLUSTERS_ALERTS,
  GET_HOSTS_ALERTS,
  GET_VMS_ALERTS,
  ACKNOWLEDGE_ALERT,
  GET_VM_REPORT,
  GET_HOST_REPORT,
  GET_CLUSTER_REPORT,
  CLEAR_REPORT_ERRORS,
} from '@/dashboard/store/constants';

export default {
  async [GET_VCENTERS]() {
    const { response: { data }, error } = await Vue.$api.vcenters.getList();

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('error'),
        text: Vue.$i18n.t('cant-fetch-vcenters'),
        type: 'error',
      });
    }

    this.vcenters = data;

    return { data, error };
  },
  async [GET_CLUSTER_CHANGES](data) {
    this.loading = true;

    const { response, error } = await Vue.$api.clusters.getChanges(data);

    this.loading = false;

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('error'),
        text: Vue.$i18n.t('error-fetching-data'),
        type: 'error',
      });
    }

    this.changes = response.data.data;
    this.totalChanges = response.data.total;

    return response;
  },
  async [GET_HOST_CHANGES](data) {
    this.loading = true;

    const { error, response } = await Vue.$api.hosts.getChanges(data);

    this.loading = false;

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('error'),
        text: Vue.$i18n.t('cant-get-host-changes'),
        type: 'error',
      });

      return;
    }

    this.changes = response.data.data;
    this.totalChanges = response.data.total;

    return response;
  },
  async [GET_VM_CHANGES](data) {
    this.loading = true;

    const { error, response } = await Vue.$api.vms.getChanges(data);

    this.loading = false;

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('error'),
        text: Vue.$i18n.t('cant-get-vm-changes'),
        type: 'error',
      });

      return;
    }

    this.changes = response.data.data;
    this.totalChanges = response.data.total;

    return response;
  },
  async [GET_CLUSTERS_ALERTS](data) {

    const { error, response } = await Vue.$api.alerts.getClustersAlerts(data);

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('alerts'),
        text: Vue.$i18n.t('cant-get-alerts'),
        type: 'error',
      });

      return;
    }

    if (!response.data) {
      return;
    }

    this.alerts.cluster = response.data.alerts;

    return response;
  },
  async [GET_HOSTS_ALERTS](data) {

    const { error, response } = await Vue.$api.alerts.getHostsAlerts(data);

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('alerts'),
        text: Vue.$i18n.t('cant-get-alerts'),
        type: 'error',
      });

      return;
    }

    if (!response.data) {
      return;
    }

    this.alerts.host = response.data.alerts;

    return response;
  },
  async [GET_VMS_ALERTS](data) {

    const { error, response } = await Vue.$api.alerts.getVmsAlerts(data);

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('alerts'),
        text: Vue.$i18n.t('cant-get-alerts'),
        type: 'error',
      });

      return;
    }

    if (!response.data) {
      return;
    }

    this.alerts.vm = response.data.alerts;

    return response;
  },
  async [ACKNOWLEDGE_ALERT](data) {

    const { error, response } = await Vue.$api.alerts.acknowledgeAlert(data);

    if (error) {
      Vue.notify({
        title: Vue.$i18n.t('alerts'),
        text: Vue.$i18n.t('cant-remove-alert'),
        type: 'error',
      });

      return;
    }

    if (!response.data) {
      return;
    }

    return response;
  },
  async [GET_VM_REPORT](data, config) {
    const { response, error } = await Vue.$api.reports.vm(data, config);

    if (error?.response?.status === 412) {
      this.pageSizeError = true;

      return;
    }

    if (error) {
      this.createReportError = true;

      return error;
    }

    this.createReportSuccess = true;

    return response;
  },
  async [GET_HOST_REPORT](data, config) {
    const { response, error } =  await Vue.$api.reports.host(data, config);

    if (error?.response?.status === 412) {
      this.pageSizeError = true;

      return;
    }

    if (error) {
      this.createReportError = true;

      return error;
    }

    this.createReportSuccess = true;

    return response;
  },
  async [GET_CLUSTER_REPORT](data, config) {
    const { response, error } = await Vue.$api.reports.cluster(data, config);

    if (error?.response?.status === 412) {
      this.pageSizeError = true;

      return error;
    }

    if (error) {
      this.createReportError = true;

      return error;
    }

    this.createReportSuccess = true;

    return response;
  },

  [CLEAR_REPORT_ERRORS]() {
    this.createReportError = false;
    this.pageSizeError = false;
    this.createReportSuccess = false;
  },
};
