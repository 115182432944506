<template>
  <div class="flex justify-between items-end text-sm">
    <div class="flex flex-col items-start">
      <DashboardPaginatorTotal
        :start="total.start"
        :end="total.end"
        :changes="total.changes"
      />
      <DashboardPaginatorSize
        :options="size.options"
        :selected="size.selected.value"
      />
    </div>
    <DashboardPaginatorPager
      :current-page="pager.currentPage"
      :per-page="pager.perPage"
      :total-pages="pager.totalPages"
    />
  </div>
</template>

<script>
import {
  computed,
  reactive,
} from '@vue/composition-api';
import { useDashboardStore } from '@/dashboard/store';
import DashboardPaginatorPager from '@/dashboard/ui/DashboardPaginatorPager.vue';
import DashboardPaginatorSize from '@/dashboard/ui/DashboardPaginatorSize.vue';
import DashboardPaginatorTotal from '@/dashboard/ui/DashboardPaginatorTotal.vue';

export default {
  components: {
    DashboardPaginatorPager,
    DashboardPaginatorSize,
    DashboardPaginatorTotal,
  },
  setup() {
    const store = useDashboardStore();

    const total = reactive({
      changes: computed(() => store.totalChanges),
      start: computed(() => (store.itemsPerPage * store.page) - store.itemsPerPage + 1),
      end: computed(() => {
        const total = store.itemsPerPage * store.page;

        if (total > store.totalChanges) {
          return store.totalChanges;
        }

        return total;
      }),
    });

    const size = {
      options: [...computed(() => store.sizeOptions).value],
      selected: computed(() => store.itemsPerPage),
    };

    const pager = computed(() => {
      let totalPages = 1;

      if (store.totalChanges > store.itemsPerPage) {
        totalPages = store.totalChanges / store.itemsPerPage;
      }

      return {
        totalPages: Math.ceil(totalPages),
        perPage: computed(() => store.itemsPerPage).value,
        currentPage: computed(() => store.page).value,
      };
    });

    return {
      size,
      pager,
      total,
    };
  },
};
</script>
