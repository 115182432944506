<template>
  <div
    class="rounded"
    :class="isParent ? 'bg-primary-dark mb-2' : 'bg-primary-darker mt-0 my-2 mx-3'"
  >
    <div
      class="flex p-4 items-center justify-between text-h4 text-left font-bold cursor-pointer"
      @click="show = !show"
    >
      <span
        class="flex cursor-pointer"
        :class="isParent ? 'text-h4' : 'text-h6'"
      >
        <component
          :is="icon"
          v-if="isParent"
          :size="20"
          class="pr-4"
        />
        {{ heading }}
      </span>
      <div class="flex items-center gap-3">
        <span class="bg-secondary text-xs text-primary-dark px-4 py-1 rounded-md font-light">
          {{ alertsCounter }} {{ $t('alerts') }}
        </span>
        <BaseToggleChevron
          class="cursor-pointer"
          :show="show"
        />
        <ArrowExpandAllIcon
          v-if="!isParent"
          class="pr-2 text-secondary cursor-pointer"
          :size="14"
          @click.native="$emit('expand', { heading, parentHeading, icon })"
        />
      </div>
    </div>
    <slot v-if="show" />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import GoogleCirclesExtendedIcon from 'vue-material-design-icons/GoogleCirclesExtended.vue';
import ServerIcon from 'vue-material-design-icons/Server.vue';
import CubeScanIcon from 'vue-material-design-icons/CubeScan.vue';
import ArrowExpandAllIcon from 'vue-material-design-icons/ArrowExpandAll.vue';
import BaseToggleChevron from '@/app/ui/BaseToggleChevron.vue';

export default {
  components: {
    ArrowExpandAllIcon,
    GoogleCirclesExtendedIcon,
    CubeScanIcon,
    ServerIcon,
    BaseToggleChevron,
  },
  props: {
    isParent: { type: Boolean, default: true },
    heading: { type: String, default: '' },
    type: { type: String, default: '' },
    parentHeading: { type: String, default: '' },
    activeSection: { type: String, default: '' },
    icon: { type: Object, default: () => ({}) },
    alertsCounter: { type: Number, default: 0 },
  },
  setup(props) {
    const show = ref(false);

    if (props.isParent && props.type === props.activeSection) {
      show.value = true;
    }

    return { show };
  },
};
</script>
