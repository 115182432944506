import { ApiService } from '@/app/services/index';
import { objectToQueryString } from '@/app/helpers/queryHelper';

export const AlertsService = {
  getHostsAlerts(data) {
    return ApiService.get(`/alerts/hosts/list${ objectToQueryString(data) }`);
  },
  getClustersAlerts(data) {
    return ApiService.get(`/alerts/clusters/list${ objectToQueryString(data) }`);
  },
  getVmsAlerts(data) {
    return ApiService.get(`/alerts/vms/list${ objectToQueryString(data) }`);
  },
  acknowledgeAlert(data) {
    return ApiService.post('/alerts/acknowledge', { id: data });
  },
};
