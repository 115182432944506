<template>
  <div class="flex gap-2 items-start flex-wrap">
    <h2>Coming soon..</h2>
    <DashboardTabStatisticsCard
      v-for="chart in charts"
      :key="chart.key"
      :chart="chart"
      class="hidden"
    />
  </div>
</template>

<script>
import DashboardTabStatisticsCard from '@/dashboard/ui/DashboardTabStatisticsCard.vue';

export default {
  components: {
    DashboardTabStatisticsCard,
  },
  props: {
    value: { type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
  },
  setup(props, { root: {$i18n} }) {

    const alerts = {
      title: $i18n.t('alerts'),
      type: 'alerts',
      size: 'small',
      data: [
        {
          name: 'Clusters',
          type: 'clusters',
          value: 10,
          total: 60,
        },
        {
          name: 'ESXi Servers',
          type: 'esxi-servers',
          value: 20,
          total: 60,
        },
        {
          name: 'VMs',
          type: 'vms',
          value: 40,
          total: 60,
        },
      ],
    };

    const clusters = {
      title: `Clusters ${ $i18n.t('with-changes-in') }`,
      type: 'clusters',
      size: 'medium',
      data: [
        {
          name: 'VMs',
          type: 'vms',
          value: 10,
          total: 60,
        }, {
          name: 'Hosts',
          type: 'hosts',
          value: 10,
          total: 60,
        },
      ],
    };

    const hosts = {
      title: `Hosts ${ $i18n.t('with-changes-in') }`,
      type: 'hosts',
      size: 'large',
      data: [
        {
          name: 'VMs',
          type: 'vms',
          value: 10,
          total: 60,
        },
        {
          name: 'CPU sockets',
          type: 'cpu-sockets',
          value: 10,
          total: 60,
        },
        {
          name: 'Network',
          type: 'network',
          value: 10,
          total: 60,
        },
        {
          name: 'CPU Cores',
          type: 'cpu-cores',
          value: 10,
          total: 60,
        },
        {
          name: 'Memory',
          type: 'memory',
          value: 10,
          total: 60,
        },
      ],
    };

    const vms = {
      title: `VMs ${ $i18n.t('with-changes-in') }`,
      type: 'vms',
      size: 'large',
      data: [
        {
          name: 'CPU sockets',
          type: 'cpu-sockets',
          value: 27,
          total: 60,
        },
        {
          name: 'Network',
          type: 'network',
          value: 27,
          total: 60,
        },
        {
          name: 'CPU Cores',
          type: 'cpu-cores',
          value: 27,
          total: 60,
        },
        {
          name: 'Memory',
          type: 'memory',
          value: 27,
          total: 60,
        },
      ],
    };

    const charts = [
      alerts,
      clusters,
      hosts,
      vms,
    ];

    return { charts };
  },
};
</script>
