import Vue from 'vue';
import router from '@/app/router';
import {
  SIGN_IN,
  SIGN_OUT,
  REFRESH_TOKEN,
} from '@/app/store/auth/constants';
import { ApiService } from '@/app/services/index';

export default {
  async [SIGN_IN](data) {

    this.loading = true;

    const form = new FormData();
    form.append('username', data.username);
    form.append('password', data.password);
    form.append('grant_type', 'password');
    form.append('scope', 'SEND_DATA READ_DATA USER_ADMIN ADMIN_READ ADMIN_WRITE');

    const { error, response } = await Vue.$api.auth.signIn(form);

    this.loading = false;

    if (error && error?.response?.status === 401) {
      Vue.notify({
        title: Vue.$i18n.t('sign-in'),
        text: Vue.$i18n.t('incorrect-username-or-password'),
        type: 'error',
      });

      return;
    }

    if (error && error?.response?.status !== 401) {
      Vue.notify({
        title: Vue.$i18n.t('sign-in'),
        text: Vue.$i18n.t('please-try-again-later'),
        type: 'error',
      });

      return;
    }

    this.accessToken = response.data?.access_token;
    this.refreshingToken = response.data?.refresh_token;

    ApiService.defaults.headers.common['Authorization'] = `Bearer ${ this.accessToken }`;

    Vue.$storage.set('auth/token', this.accessToken);
    Vue.$storage.set('auth/refreshingToken', this.refreshingToken);
    router.push('/');

    return response;
  },

  [SIGN_OUT]() {
    this.accessToken = null;

    delete ApiService.defaults.headers.common['Authorization'];

    Vue.$storage.clear();
  },

  async [REFRESH_TOKEN](data) {
    const { response } = await Vue.$api.auth.refreshToken(data);

    this.accessToken = response.data?.access_token;
    this.refreshToken = response.data?.refresh_token;

    Vue.$storage.set('auth/token', this.accessToken);
    Vue.$storage.set('auth/refreshingToken', this.refreshingToken);
  },
};
