export const DASHBOARD_MODULE = 'dashboard';

// actions
export const GET_VCENTERS = 'getVcenters';
export const GET_CLUSTER_CHANGES = 'getClusterChanges';
export const GET_HOST_CHANGES = 'getHostChanges';
export const GET_VM_CHANGES = 'getVmChanges';
export const GET_HOSTS_ALERTS = 'getHostsAlerts';
export const GET_CLUSTERS_ALERTS = 'getClustersAlerts';
export const GET_VMS_ALERTS = 'getVmsAlerts';
export const ACKNOWLEDGE_ALERT = 'acknowledgeAlert';
export const GET_VM_REPORT = 'getVmReport';
export const GET_CLUSTER_REPORT = 'getClusterReport';
export const GET_HOST_REPORT = 'getHostReport';
export const CLEAR_REPORT_ERRORS = 'clearReportErrors';

// consts
export const TABS = {
  alerts: 'alerts',
  cluster: 'cluster',
  extension: 'extension',
  host: 'host',
  statistics: 'statistics',
  vm: 'vm',
};
export const CHANGE_TYPE_DIRECTION = {
  up: 'up',
  down: 'down',
};
