<template>
  <div class="flex-1 flex flex-col gap-4 mt-4">
    <div class="flex gap-4 flex-1">
      <div class="flex flex-col gap-4 item-stretch w-64">
        <DashboardDatePicker />
      </div>
      <div class="relative flex-1">
        <div
          v-if="settings.loading"
          class="flex h-96 items-center justify-center"
        >
          <BaseLoader
            class="h-8 w-8"
            :loading="settings.loading"
            :size="36"
          />
        </div>
        <div
          v-else
          :class="{ 'bg-primary-dark p-2 rounded': isDetailsViewOpen }"
        >
          <DashboardTabAlertsDetailView
            v-if="isDetailsViewOpen"
            :group="alerts.expandedGroup"
            :section="alerts.expandedSection"
            :data="groupAlerts"
            @goBack="goBack"
            @acknowledge="acknowledge"
          />
          <template v-else>
            <DashboardTabAlertsCollapse
              v-for="section in sections"
              :key="section.key"
              :heading="section.name"
              :icon="section.icon"
              :active-section="alerts.activeSectionType"
              :type="section.type"
              :alerts-counter="section.amount"
            >
              <template v-if="section.amount">
                <DashboardTabAlertsCollapse
                  v-for="group in section.groups"
                  :key="group.key"
                  :heading="group.label"
                  :icon="section.icon"
                  :is-parent="false"
                  :parent-heading="section.name"
                  :alerts-counter="group.items.length"
                  :class="{ 'hidden': !group.items.length }"
                  @expand="expand(section, group)"
                >
                  <ve-table
                    class="inner-table p-2"
                    :columns="columns"
                    :table-data="group.items.slice(0, 5)"
                  />
                </DashboardTabAlertsCollapse>
                <div class="h-1" />
              </template>
              <template v-else>
                <p class="p-4 my-2 mx-3 text-center bg-primary-darker rounded">
                  {{ $t('no-data-is-present') }}
                </p>
                <div class="h-1" />
              </template>
            </DashboardTabAlertsCollapse>
            <!-- Hide charts until back-end is ready -->
            <DashboardTabAlertsCharts
              v-if="false"
              :data="charts"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  watchEffect,
  onBeforeMount,
} from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import GoogleCirclesExtendedIcon from 'vue-material-design-icons/GoogleCirclesExtended.vue';
import ServerIcon from 'vue-material-design-icons/Server.vue';
import CubeScanIcon from 'vue-material-design-icons/CubeScan.vue';
import CheckboxMarkedCircleOutlineIcon from 'vue-material-design-icons/CheckboxMarkedCircleOutline.vue';
import CheckboxBlankCircleOutlineIcon from 'vue-material-design-icons/CheckboxBlankCircleOutline.vue';
import BaseLoader from '@/app/ui/BaseLoader.vue';
import DashboardDatePicker from '@/dashboard/ui/DashboardDatePicker.vue';
import DashboardTabAlertsCollapse from '@/dashboard/ui/DashboardTabAlertsCollapse.vue';
import DashboardTabAlertsCharts from '@/dashboard/ui/DashboardTabAlertsCharts.vue';
import DashboardTabAlertsDetailView from '@/dashboard/ui/DashboardTabAlertsDetailView.vue';
import { useDashboardStore } from '@/dashboard/store';
import {
  ACKNOWLEDGE_ALERT,
  GET_CLUSTERS_ALERTS,
  GET_HOSTS_ALERTS,
  GET_VMS_ALERTS,
} from '@/dashboard/store/constants';

export default {
  components: {
    BaseLoader,
    DashboardDatePicker,
    DashboardTabAlertsCollapse,
    DashboardTabAlertsCharts,
    DashboardTabAlertsDetailView,
  },
  props: {
    params: { type: Object, default: () => ({}) },
    settings: { type: Object, default: () => ({}) },
  },
  setup(props, { root: { $i18n } }) {
    // Store
    const store = useDashboardStore();
    const getClustersAlerts = store[GET_CLUSTERS_ALERTS];
    const getHostsAlerts = store[GET_HOSTS_ALERTS];
    const getVmsAlerts = store[GET_VMS_ALERTS];
    const acknowledgeAlert = store[ACKNOWLEDGE_ALERT];
    const {
      loading,
      alerts,
      groupAlerts,
      clusterAlertsGroups,
      hostAlertsGroups,
      vmAlertsGroups,
    } = storeToRefs(store);

    const query = computed(() => {
      return {
        fromWhen: props.params.fromWhen,
        toWhen: props.params.toWhen,
        acknowledged: false,
      };
    });

    // Hook
    onBeforeMount(async() => {
      loading.value = true;
      await Promise.all([
        getClustersAlerts(query.value),
        getHostsAlerts(query.value),
        getVmsAlerts(query.value),
      ]);
      loading.value = false;
    });

    // Watcher
    watchEffect(async() => {
      loading.value = true;
      await Promise.all([
        getClustersAlerts(query.value),
        getHostsAlerts(query.value),
        getVmsAlerts(query.value),
      ]);
      loading.value = false;
    });

    const isDetailsViewOpen = computed(() =>
      (alerts.value.expandedGroup && Object.entries(alerts.value.expandedGroup).length)
      && (alerts.value.expandedSection && Object.entries(alerts.value.expandedSection).length),
    );

    // Expand section
    function expand(section, group) {
      alerts.value.expandedGroup = group;
      alerts.value.expandedSection = section;
    }

    // Get on prev. screen
    function goBack(val) {
      alerts.value.expandedGroup = {};
      alerts.value.expandedSection = {};
      alerts.value.activeSectionType = val;
    }

    // Acknowledge alert
    function acknowledge(alertId) {
      alerts.value.acknowledgeAlertId = alertId;
      acknowledgeAlert(alertId);
    }

    const sections = computed(() => [
      {
        name: 'Clusters',
        type: 'cluster',
        icon: GoogleCirclesExtendedIcon,
        groups: clusterAlertsGroups.value.data,
        amount: clusterAlertsGroups.value.amount,
      },
      {
        name: 'Hosts',
        type: 'host',
        icon: ServerIcon,
        groups: hostAlertsGroups.value.data,
        amount: hostAlertsGroups.value.amount,
      },
      {
        name: 'VMs',
        type: 'vm',
        icon: CubeScanIcon,
        groups: vmAlertsGroups.value.data,
        amount: vmAlertsGroups.value.amount,
      },
    ]);

    const columns = [
      {
        field: 'name',
        title: $i18n.t('name'),
        key: 'name',
        align: 'center',
      },
      {
        field: 'time',
        title: $i18n.t('time'),
        key: 'time',
        align: 'center',
        renderBodyCell: ({ row: { time } }) => new Date(time).toUTCString(),
      },
      {
        field: 'description',
        title: $i18n.t('description'),
        key: 'description',
        align: 'center',
      },
      {
        field: 'acknowledge',
        title: $i18n.t('acknowledge'),
        key: 'acknowledge',
        align: 'center',
        renderBodyCell: ({ row }, h) => {
          const icon = row.acknowledged ? CheckboxMarkedCircleOutlineIcon : CheckboxBlankCircleOutlineIcon;

          return h(
            icon, {
              class: 'inline-block cursor-pointer',
              attrs: { size: 16 },
              on: {
                click: () => {
                  if (row.acknowledged) {
                    return;
                  }
                  row.acknowledged = true;
                  acknowledge(row.id);
                },
              },
            },
          );
        },
      },
    ];

    const charts = [
      {
        name: 'loremIpsumdisk',
        value: 100,
        total: 128,
      },
      {
        name: 'pdisk',
        value: 40,
        total: 128,
      },
      {
        name: 'rootdisk',
        value: 80,
        total: 128,
      },
      {
        name: 'LIpsumdisk',
        value: 100,
        total: 128,
      },
    ];

    return {
      alerts,
      charts,
      loading,
      columns,
      sections,
      groupAlerts,
      isDetailsViewOpen,
      expand,
      goBack,
      acknowledge,
    };
  },
};
</script>

<style lang="postcss">
.inner-table.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body.ve-table-row-hover tr.ve-table-body-tr:hover td {
  @apply bg-primary-dark-tint-1;
}
.inner-table.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body.ve-table-row-hover tr.ve-table-body-tr:hover td:first-child {
  @apply rounded-l;
}
.inner-table.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body.ve-table-row-hover tr.ve-table-body-tr:hover td:last-child {
  @apply rounded-r;
}
.inner-table.ve-table .ve-table-container table.ve-table-content tbody.ve-table-body.ve-table-row-hover tr.ve-table-body-tr td {
  @apply text-xs;
}
</style>
