<template>
  <div class="p-6.5 flex flex-col gap-2">
    <div class="flex justify-between items-center">
      <DashboardNavTop />
      <div class="flex gap-2">
        <button
          class="btn flex gap-1 px-4 rounded-lg font-bold"
          :class="isVerified ? 'bg-primary hover:bg-primary text-primary-dark' : 'bg-accent hover:bg-accent text-light'"
          type="button"
          @click="getStatus"
        >
          <CheckIcon v-if="isVerified" />
          <CloseIcon v-else />
          {{ isVerified ? $t('verified') : $t('tampered') }}
        </button>
        <BaseButton
          :text="$t('create-a-report')"
          :disable="disableCreateReportButton"
          @click.native="openReportModal"
        />
      </div>
    </div>
    <component
      :is="tab"
      :params="params"
      :settings="settings"
    />
    <portal to="body">
      <DashboardCreateReport
        :visibility="isReportModalVisible"
        :params="params"
        :tab-name="activeTab"
        @closeReportModal="isReportModalVisible = false"
      />
    </portal>
  </div>
</template>

<script>
import {
  watch,
  toRefs,
  computed,
  reactive,
  ref,
  onBeforeMount,
} from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import CheckIcon from 'vue-material-design-icons/Check.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import { getDateBefore } from '@/app/helpers/dateHelper';
import DashboardCreateReport from '@/dashboard/ui/DashboardCreateReport.vue';
import DashboardNavTop from '@/dashboard/ui/DashboardNavTop.vue';
import DashboardTabAlerts from '@/dashboard/ui/DashboardTabAlerts.vue';
import DashboardTabCluster from '@/dashboard/ui/DashboardTabCluster.vue';
import DashboardTabExtension from '@/dashboard/ui/DashboardTabExtension.vue';
import DashboardTabHost from '@/dashboard/ui/DashboardTabHost.vue';
import DashboardTabStatistics from '@/dashboard/ui/DashboardTabStatistics.vue';
import DashboardTabVm from '@/dashboard/ui/DashboardTabVm.vue';
import { useDashboardStore } from '@/dashboard/store';
import {
  TABS,
  GET_VCENTERS,
  CLEAR_REPORT_ERRORS,
} from '@/dashboard/store/constants';
import BaseButton from '@/app/ui/BaseButton';

export default {
  components: {
    BaseButton,
    CheckIcon,
    CloseIcon,
    DashboardCreateReport,
    DashboardNavTop,
    DashboardTabAlerts,
    DashboardTabCluster,
    DashboardTabExtension,
    DashboardTabHost,
    DashboardTabStatistics,
    DashboardTabVm,
  },
  setup() {
    // Store
    const store = useDashboardStore();
    const {
      page,
      dates,
      loading,
      changes,
      activeTab,
      itemsPerPage,
      searchString,
      isViewExtended,
      responseIncludesSelected,
    } = storeToRefs(store);
    const getVcenters = store[GET_VCENTERS];
    const clearReportErrors = store[CLEAR_REPORT_ERRORS];

    // Constants
    const isVerified = computed(() => 'verified'); // @TODO: replace temp. solution
    const tab = computed(() => {
      switch(activeTab.value) {
      case(TABS.alerts):
        return DashboardTabAlerts;
      case(TABS.host):
        return DashboardTabHost;
      case(TABS.vm):
        return DashboardTabVm;
      case(TABS.extension):
        return DashboardTabExtension;
      case(TABS.statistics):
        return DashboardTabStatistics;
      default:
        return DashboardTabCluster;
      }
    });
    const disableCreateReportButton = computed(() => {
      const allowedTabs = [
        TABS.host,
        TABS.vm,
        TABS.cluster,
      ];

      return !allowedTabs.includes(activeTab.value);
    });

    const isReportModalVisible = ref(false);

    dates.value.start = getDateBefore(30);
    dates.value.end = new Date();

    // State
    const state = reactive({
      params: {
        fromWhen: computed(() => new Date(dates.value.start).toJSON()),
        toWhen: computed(() => new Date(dates.value.end).toJSON()),
        search: searchString,
        responseIncludes: responseIncludesSelected,
        pageSize: itemsPerPage,
        page: computed(() => page.value - 1),
      },
      settings: {
        loading,
        changes,
        isViewExtended,
        itemsNotFound: computed(() => !loading.value && !changes.value.length),
      },
    });

    // Hooks
    onBeforeMount(async() => await getVcenters());

    // Watcher
    watch([
      activeTab,
      searchString,
      responseIncludesSelected,
      () => state.params.toWhen,
    ], (newVal) => {
      if (newVal) {
        page.value = 1;
      }
    });

    function getStatus() {
      alert(`Click status "${ isVerified.value }"`);
    }

    function openReportModal() {
      isReportModalVisible.value = true;
      clearReportErrors();
    }

    return {
      disableCreateReportButton,
      tab,
      loading,
      activeTab,
      isVerified,
      isReportModalVisible,
      ...toRefs(state),
      openReportModal,
      getStatus,
    };
  },
};
</script>

