import { ApiService } from '@/app/services/index';

export const SslSettingsService = {
  getSslSettings() {
    return ApiService.get('/system/ssl/get');
  },
  saveSslSettings(data) {
    return ApiService.put('/system/ssl/update', data);
  },
};
