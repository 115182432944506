<template>
  <div
    class="dashboard-card | p-4 rounded-xl shadow-xl bg-primary-dark cursor-pointer"
    :class="{ 'flex bg-red gap-2 full-width' : isExtended }"
  >
    <DashboardCardHeader
      :data="data"
      :icon="icon"
      :is-extended="isExtended"
    />
    <DashboardCardTable
      class="flex-1"
      :data="data"
      :size="5"
    />
  </div>
</template>

<script>
import DashboardCardHeader from '@/dashboard/ui/DashboardCardHeader.vue';
import DashboardCardTable from '@/dashboard/ui/DashboardCardTable.vue';

export default {
  components: {
    DashboardCardHeader,
    DashboardCardTable,
  },
  props: {
    data: { type: Object, default: () => ({}) },
    icon: { type: String, default: 'GoogleCirclesExtendedIcon' },
    isExtended: { type: Boolean, default: false },
  },
};
</script>
