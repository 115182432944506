import { ApiService } from '@/app/services/index';

export const LicenseService = {
  getLicenses() {
    return ApiService.get('/license/list');
  },
  addLicense(data) {
    return ApiService.put('/license/add', data);
  },
  deleteLicense(data) {
    return ApiService.delete(`/license/delete?licenceId=${ data }`);
  },
};
