import { promiseHelper } from '@/app/helpers/promiseHelper';
import { AuthService } from '@/app/services/auth';
import { LicenseService } from '@/app/services/license';
import { SslSettingsService } from '@/app/services/ssl-settings';
import { SupportChatService } from '@/app/services/support-chat';
import { SystemDiagnosticService } from '@/app/services/system-maintenance';
import { SystemUpdateService } from '@/app/services/system-update';
import { MailService } from '@/app/services/mail';
import { LdapService } from '@/app/services/ldap';
import { VmwareService } from '@/app/services/vmware';
import { HostsService } from '@/app/services/hosts';
import { VmsService } from '@/app/services/vms';
import { ClustersService } from '@/app/services/clusters';
import { VcentersService } from '@/app/services/vcenters';
import { AlertsService } from '@/app/services/alerts';
import { RulesService } from '@/app/services/rules';
import { ReportsServices } from '@/app/services/reports';

const api = {
  auth: {
    signIn(payload) {
      return promiseHelper(AuthService.signIn(payload));
    },
    refreshToken(payload) {
      return promiseHelper(AuthService.refreshToken(payload));
    },
  },
  licenses: {
    get() {
      return promiseHelper(LicenseService.getLicenses());
    },
    add(payload) {
      return promiseHelper(LicenseService.addLicense(payload));
    },
    delete(payload) {
      return promiseHelper(LicenseService.deleteLicense(payload));
    },
  },
  sslSettings: {
    get() {
      return promiseHelper(SslSettingsService.getSslSettings());
    },
    save(payload) {
      return promiseHelper(SslSettingsService.saveSslSettings(payload));
    },
  },
  supportChat: {
    get() {
      return promiseHelper(SupportChatService.getSupportChatStatus());
    },
    enable() {
      return promiseHelper(SupportChatService.enableSupportChat());
    },
    disable() {
      return promiseHelper(SupportChatService.disableSupportChat());
    },
  },
  systemDiagnosticFile: {
    get() {
      return promiseHelper(SystemDiagnosticService.getSystemDiagnosticFile());
    },
  },
  uploadSystemUpdateFile: {
    save(payload, config) {
      return promiseHelper(SystemUpdateService.uploadSystemUpdateFile(payload, config));
    },
    progress() {
      return promiseHelper(SystemUpdateService.uploadSystemProgress());
    },
    version() {
      return promiseHelper(SystemUpdateService.version());
    },
  },
  mail: {
    get() {
      return promiseHelper(MailService.getMailSettings());
    },
    update(payload) {
      return promiseHelper(MailService.updateMailSettings(payload));
    },
    delete() {
      return promiseHelper(MailService.deleteMailSettings());
    },
    test() {
      return promiseHelper(MailService.testMailSettings());
    },
  },
  ldap: {
    check(payload) {
      return promiseHelper(LdapService.checkConnection(payload));
    },
    groups(payload) {
      return promiseHelper(LdapService.getGroups(payload));
    },
    get() {
      return promiseHelper(LdapService.getSettings());
    },
    update(payload) {
      return promiseHelper(LdapService.updateSettings(payload));
    },
  },
  vmware: {
    get() {
      return promiseHelper(VmwareService.getHosts());
    },
    add(payload) {
      return promiseHelper(VmwareService.addHost(payload));
    },
    update(payload) {
      return promiseHelper(VmwareService.updateHost(payload));
    },
    delete(payload) {
      return promiseHelper(VmwareService.deleteHost(payload));
    },
    test(payload) {
      return promiseHelper(VmwareService.testHost(payload));
    },
  },
  hosts: {
    getChanges(payload) {
      return promiseHelper(HostsService.getHostsChanges(payload));
    },
  },
  vms: {
    getChanges(payload) {
      return promiseHelper(VmsService.getVmsChanges(payload));
    },
  },
  clusters: {
    getChanges(payload) {
      return promiseHelper(ClustersService.getClustersChanges(payload));
    },
  },
  vcenters: {
    getList() {
      return promiseHelper(VcentersService.getList());
    },
  },
  alerts: {
    getHostsAlerts(payload) {
      return promiseHelper(AlertsService.getHostsAlerts(payload));
    },
    getClustersAlerts(payload) {
      return promiseHelper(AlertsService.getClustersAlerts(payload));
    },
    getVmsAlerts(payload) {
      return promiseHelper(AlertsService.getVmsAlerts(payload));
    },
    acknowledgeAlert(payload) {
      return promiseHelper(AlertsService.acknowledgeAlert(payload));
    },
  },
  rules: {
    get() {
      return promiseHelper(RulesService.getRules());
    },
    set(payload) {
      return promiseHelper(RulesService.setRule(payload));
    },
    listSystem(payload) {
      return promiseHelper(RulesService.listSystem(payload));
    },
  },
  reports: {
    vm(payload, config) {
      return promiseHelper(ReportsServices.vm(payload, config));
    },
    host(payload, config) {
      return promiseHelper(ReportsServices.host(payload, config));
    },
    cluster(payload, config) {
      return promiseHelper(ReportsServices.cluster(payload, config));
    },
  },
};

export default {
  install(Vue) {
    Vue.$api = api;
  },
};
