<template>
  <button
    type="button"
    class="md:hidden fixed flex items-center justify-center h-12 w-12 p-3 text-white bg-primary rounded-full outline-none bottom-10 right-2 shadow-md z-50"
  >
    <WindowCloseIcon v-if="buttonPressed" />
    <MenuIcon v-else />
  </button>
</template>

<script>
import WindowCloseIcon from 'vue-material-design-icons/WindowClose.vue';
import MenuIcon from 'vue-material-design-icons/Menu.vue';

export default {
  components: {
    WindowCloseIcon,
    MenuIcon,
  },
  props: {
    buttonPressed: { type: Boolean, default: false },
  },
};
</script>
