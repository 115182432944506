<template>
  <div
    class="flex items-center text-sm"
    :class="isExtended ? 'flex-col gap-2 justify-center' : 'justify-between flex-wrap'"
  >
    <div
      class="flex font-bold items-center"
      :class="isExtended ? 'flex-col justify-center' : 'justify-between flex-wrap'"
    >
      <component
        :is="icon"
        :size="isExtended ? 21 : 13"
        :class="{ 'mb-4': isExtended }"
      />
      <span class="ml-1">{{ host }}</span>
      <ChevronDownIcon v-if="isExtended" />
      <ChevronRightIcon v-else />
      <span>{{ data.name }}</span>
    </div>
    <div class="flex gap-2 items-center">
      <span class="flex items-center bg-secondary text-dark rounded-xl py-0.5 px-2 lowercase text-xs">
        {{ $t('number-of-changes') }}: {{ data.changes.length }}
      </span>
      <ArrowExpandAllIcon
        v-if="!isExtended"
        class="text-secondary"
        :size="14"
      />
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import ArrowExpandAllIcon from 'vue-material-design-icons/ArrowExpandAll.vue';
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import ChevronDownIcon from 'vue-material-design-icons/ChevronDown.vue';
import GoogleCirclesExtendedIcon from 'vue-material-design-icons/GoogleCirclesExtended.vue';
import ServerIcon from 'vue-material-design-icons/Server.vue';
import CubeScanIcon from 'vue-material-design-icons/CubeScan.vue';
import { useDashboardStore } from '@/dashboard/store';

export default {
  components: {
    GoogleCirclesExtendedIcon,
    ArrowExpandAllIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    CubeScanIcon,
    ServerIcon,
  },
  props: {
    data: { type: Object, default: () => ({}) },
    icon: { type: String, required: true },
    isExtended: { type: Boolean, default: false },
  },
  setup(props) {
    // Store
    const store = useDashboardStore();

    // Computed
    const host = computed(() => {
      const item = store.vcenters.find((el) => el.uuid === props.data.vcenter_uuid);

      return item.host;
    });

    return { host };
  },
};
</script>
