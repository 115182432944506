<template>
  <div class="date-picker | max-w-xs rounded">
    <div class="date-picker__relatives-dates | flex border-b border-primary-darker py-2 px-0.5">
      <button
        v-for="date in relativeDates"
        :key="date.key"
        class="btn block text-xs bg-primary-darker w-full px-0 mx-0.5 capitalize"
        @click="selectDates(date.period)"
      >
        {{ date.name }}
      </button>
    </div>
    <date-picker
      ref="picker"
      :value="rangeDates"
      mode="dateTime"
      is-range
      color="custom-yellow"
      @input="$emit('date-selected', $event)"
    />
  </div>
</template>

<script>
import {
  ref,
  computed,
  reactive,
} from '@vue/composition-api';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { getDateBefore } from '@/app/helpers/dateHelper';

export default {
  components: {
    DatePicker,
  },
  props: {
    dates: {
      type: Object,
      default: () => ({
        start: null,
        end: null,
      }),
    },
  },
  setup(props, { root: { $i18n } }) {
    // CONTANTS
    const relativeDates = [
      { name: `${ $i18n.t('last-week') }`, period: 7 },
      { name: `${ $i18n.t('last-month') }`, period: 30 },
      { name: `${ $i18n.t('last-year') }`, period: 365 },
    ];

    // REFS
    const picker = ref(null);
    const range = reactive({
      start: props.dates?.start || new Date(),
      end: props.dates?.end || getDateBefore(7),
    });

    // COMPUTED PROPS
    const rangeDates = computed(() => {
      return {
        start: range.start,
        end: range.end,
      };
    });

    // Select relatives dates
    function selectDates(period) {
      const isOnDefaultRanges = [
        7,
        30,
        365,
      ].includes(period);
      if (isOnDefaultRanges) {
        range.start = getDateBefore(period);
        range.end = new Date();
        picker.value.move(new Date());
      }
    }

    return {
      picker,
      rangeDates,
      selectDates,
      relativeDates,
    };
  },
};
</script>

<style lang="postcss">
.date-picker {
  @apply
  bg-primary-dark;
}
.date-picker .vc-container {
  @apply
  bg-primary-dark
  border-0
  text-light
  w-full;
}
.vc-container.vc-custom-yellow {
  --accent-100: var(--secondary);
  --accent-200: var(--secondary);
  --accent-300: var(--secondary);
  --accent-400: var(--grey-dark);
  --accent-500: var(--grey-dark);
  --accent-600: var(--grey-dark);
  --custom-yellow-100: var(--secondary);
  --custom-yellow-200: var(--secondary);
  --custom-yellow-300: var(--secondary);
  --custom-yellow-400: var(--secondary);
  --custom-yellow-500: var(--secondary);
  --custom-yellow-600: var(--secondary);
  --custom-yellow-700: var(--secondary);
  --custom-yellow-800: var(--secondary);
  --custom-yellow-900: var(--light);
}
.date-picker .vc-container .vc-title {
  @apply
  leading-7
  text-sm
  text-light;
}
.date-picker .vc-container .vc-day .vc-day-content {
  @apply
  text-xs;
}
.date-picker .vc-container  .vc-nav-item {
  @apply
  border-0;
}
.date-picker .vc-container .vc-arrow:hover {
  @apply
  bg-transparent;
}
.date-picker .vc-container .vc-nav-item.is-active {
  @apply
  bg-grey-dark;
}
.date-picker .vc-container .vc-day.in-next-month {
  @apply
  hidden;
}
.date-picker .vc-container .vc-date {
  @apply
  text-xs;
}
.date-picker .vc-container .vc-date .vc-weekday {
  @apply
  font-normal
  text-grey;
}
.date-picker .vc-container .vc-date .vc-month,
.date-picker .vc-container .vc-date .vc-day {
  @apply
  font-normal
  text-secondary;
}
.date-picker .vc-container .vc-select select {
  @apply
  border-0
  bg-primary-darker
  text-light
  rounded
  w-16;
}
.date-picker .vc-container .vc-select select:focus {
  @apply
  bg-primary-darker;
}
.date-picker .vc-container .vc-pane-layout {
  @apply
  mb-2;
}
.date-picker .vc-container .vc-date-time {
  @apply
  w-full
  ml-0;
}
.date-picker .vc-container .vc-date-time .vc-am-pm {
  @apply
  border-0
  bg-primary-darker
  text-light
  m-auto
  mr-0
  rounded;
}
.date-picker .vc-container .vc-date-time .vc-am-pm button {
  @apply
  rounded
  text-light;
}
.date-picker .vc-container .vc-date-time .vc-am-pm button:focus {
  @apply
  border-transparent;
}
.date-picker .vc-container .vc-time-icon {
  @apply
  hidden;
}
.date-picker .vc-container .vc-time-picker.vc-bordered {
  @apply
  border-primary-darker;
}
.date-picker .vc-container .vc-highlight,
.date-picker .vc-container .vc-highlight.vc-highlight-base-start,
.date-picker .vc-container .vc-highlight.vc-highlight-base-middle,
.date-picker .vc-container .vc-highlight.vc-highlight-base-end,
.date-picker .vc-container .vc-day-content:focus,
.date-picker .vc-container .vc-day-content:hover {
  --white: var(--secondary);

  @apply
  opacity-100
  bg-secondary;
}
.date-picker .vc-container .vc-day-content,
.date-picker .vc-container .vc-day-content:hover,
.date-picker .vc-container .vc-day-content:focus {
  --white: var(--light);
}
</style>
