import { ApiService } from '@/app/services/index';

export const VmwareService = {
  getHosts() {
    return ApiService.get('/settings/vsphere/get');
  },
  addHost(data) {
    return ApiService.put('/settings/vsphere/add', data);
  },
  updateHost(data) {
    return ApiService.put('/settings/vsphere/update', data);
  },
  deleteHost(data) {
    return ApiService.delete(`/settings/vsphere/delete?vsphereHost=${ data.host }`);
  },
  testHost(data) {
    return ApiService.post(`/settings/vsphere/test?vsphereHost=${ data.host }`);
  },
};
