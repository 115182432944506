import $i18n from '@/i18n';
import { TABS } from '@/dashboard/store/constants';

export default () => ({
  loading: false,
  vcenters: [],
  changes: [],
  dates: {
    start: '',
    end: '',
  },
  alerts: {
    cluster: {},
    host: {},
    vm: {},
    activeSectionType: '',
    acknowledgeAlertId: null,
    expandedGroup: {},
    expandedSection: {},
  },
  searchString: '',
  isViewExtended: false,
  totalChanges: 0,
  page: 1,
  itemsPerPage: 4,
  sizeOptions: [
    4,
    10,
    20,
    50,
  ],
  tabs: [
    { value: TABS.cluster, label: $i18n.t('cluster'), selected: true },
    { value: TABS.host, label: $i18n.t('host'), selected: false },
    { value: TABS.vm, label: $i18n.t('vm'), selected: false },
    { value: TABS.statistics, label: $i18n.t('statistics'), selected: false },
    { value: TABS.extension, label: $i18n.t('extension'), selected: false },
    { value: TABS.alerts, label: $i18n.t('alerts'), selected: false },
  ],
  responseIncludes: [
    {
      value: 'hostChanges',
      label: $i18n.t('host'),
      includedIn: [TABS.cluster],
      selected: false,
    },
    {
      value: 'hostTransition',
      label: $i18n.t('host-transition'),
      includedIn: [TABS.vm],
      selected: false,
    },
    {
      value: 'vmChanges',
      label: $i18n.t('vm'),
      includedIn: [TABS.cluster, TABS.host],
      selected: false,
    },
    {
      value: 'networkChanges',
      label: $i18n.t('network'),
      includedIn: [TABS.cluster],
      selected: false,
    },
    {
      value: 'numberOfCPU',
      label: $i18n.t('numberOfCPU'),
      selected: false,
      includedIn: [TABS.vm],
    },
    {
      value: 'numberOfCPUSocket',
      label: $i18n.t('cpu-sockets'),
      includedIn: [
        TABS.cluster,
        TABS.host,
        TABS.vm,
      ],
      selected: false,
    },
    {
      value: 'numberOfCPUCores',
      label: $i18n.t('cpu-cores'),
      selected: false,
      includedIn: [
        TABS.cluster,
        TABS.host,
        TABS.vm,
      ],
    },
    {
      value: 'stateTransition',
      label: $i18n.t('state'),
      selected: false,
      includedIn: [
        TABS.cluster,
        TABS.host,
        TABS.vm,
      ],
    },
    {
      value: 'cpuFreq',
      label: $i18n.t('cpu-freq'),
      selected: false,
      includedIn: [TABS.host],
    },
    {
      value: 'memorySize',
      label: $i18n.t('memory-size'),
      selected: false,
      includedIn: [TABS.host, TABS.vm],
    },
    {
      value: 'networkName',
      label: $i18n.t('network-name'),
      selected: false,
      includedIn: [TABS.host, TABS.vm],
    },
    {
      value: 'hostPortGroup',
      label: $i18n.t('host-port-group'),
      selected: false,
      includedIn: [TABS.host],
    },
    {
      value: 'distributedPortGroup',
      label: $i18n.t('distributed-port-group'),
      selected: false,
      includedIn: [TABS.host],
    },
    {
      value: 'clusterTransition',
      label: $i18n.t('cluster-transition'),
      selected: false,
      includedIn: [TABS.host, TABS.vm],
    },
  ],
  createReportError: false,
  createReportSuccess: false,
  pageSizeError: false,
});
