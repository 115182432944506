<template>
  <BaseModal
    v-if="visibility"
    small
    darker
    @close="closeHandler"
  >
    <component
      :is="reportComponent"
      :is-loading="isLoading"
      :tab-name="tabName"
      :params="params"
      @cancel="closeHandler"
      @create-report="createReport"
      @create-force-report="payload => createReport(payload, true)"
    />
  </BaseModal>
</template>

<script>

import {
  computed,
  ref,
} from '@vue/composition-api';
import BaseModal from '@/app/ui/BaseModal.vue';
import DashboardGeneralReport from '@/dashboard/ui/DashboardGeneralReport.vue';
import DashboardAlertsReport from '@/dashboard/ui/DashboardAlertsReport.vue';
import {
  GET_CLUSTER_REPORT,
  GET_HOST_REPORT,
  GET_VM_REPORT,
  TABS,
} from '@/dashboard/store/constants';
import { useDashboardStore } from '@/dashboard/store';
import { downloadHelper } from '@/app/helpers/downloadHelper';

export default {
  components: {
    DashboardGeneralReport,
    BaseModal,
  },
  props: {
    visibility: { type: Boolean, default: false },
    tabName: { type: String, default: '' },
    params: { type: Object, default: () => {} },
  },
  setup(props) {
    // Store
    const store = useDashboardStore();
    const getVmReport = store[GET_VM_REPORT];
    const getClusterReport = store[GET_CLUSTER_REPORT];
    const getHostReport = store[GET_HOST_REPORT];

    const isLoading = ref(false);
    let controller = null;

    const reportComponent = computed(() => {
      switch(props.tabName) {
      case(TABS.host):
      case(TABS.vm):
      case(TABS.extension):
      case(TABS.cluster):
      case(TABS.statistics):
        return DashboardGeneralReport;
      case(TABS.alerts):
        return DashboardAlertsReport;
      default:
        return DashboardGeneralReport;
      }
    });

    async function createReport(payload, force = false) {
      isLoading.value = true;
      const mergedPayload = {
        fromWhen: props.params.fromWhen,
        toWhen: props.params.toWhen,
        responseIncludes: props.params.responseIncludes,
        force,
        ...payload,
      };
      let response;
      controller = new AbortController();
      const signal = controller.signal;

      if (props.tabName === TABS.host) {
        response = await getHostReport(mergedPayload, { signal });
      } else if (props.tabName === TABS.vm) {
        response = await getVmReport(mergedPayload, { signal });
      } else if (props.tabName === TABS.cluster) {
        response = await getClusterReport(mergedPayload, { signal });
      } else if (props.tabName === TABS.alerts) {
        response = await getClusterReport(mergedPayload, { signal });
      }

      isLoading.value = false;

      if (
        response?.message === 'canceled'
        || response?.message?.includes('timeout')
        || response.message?.includes('fail')
      ) {
        return;
      }

      if (response?.status === 200) {
        downloadHelper(response);
      }
    }

    function closeHandler() {
      if (controller) {
        controller.abort();
      }

      this.$emit('closeReportModal');
    }

    return {
      reportComponent,
      isLoading,
      closeHandler,
      createReport,
    };
  },
};
</script>
