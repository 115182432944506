<template>
  <div
    id="app"
    class="min-h-full flex"
  >
    <router-view name="TheSidebar" />
    <main class="main | min-h-full h-full w-full md:pl-24">
      <router-view class="main__content | min-h-full h-full w-full" />
    </main>
    <portal-target name="body" />
    <TheNotifications />
  </div>
</template>

<script>
import {
  computed,
  onMounted,
  onUnmounted,
  watch,
} from '@vue/composition-api';
import { useIdle } from '@vueuse/core';
import TheNotifications from '@/app/ui/TheNotifications.vue';
import { REFRESH_TOKEN } from '@/app/store/auth/constants';
import { useAuthStore } from '@/app/store/auth/index';

export default {
  components: {
    TheNotifications,
  },
  setup(props, { root: { $i18n, $storage, $router, $notify } }) {

    // Store
    const store = useAuthStore();
    const refreshToken = store[REFRESH_TOKEN];
    const isLoggedIn = computed(() => store.accessToken);

    // Hooks
    onMounted(() => refreshTokenHandler());
    onUnmounted(() => refreshTokenHandler());

    // Idle
    const { idle } = useIdle(15 * 60 * 1000);
    watch(idle, (val) => {
      if (val) {
        $router.push('/login');
        $notify({
          text: $i18n.t('you-have-been-logged-out-due-inactivity'),
          type: 'error',
          duration: -1,
        });
      }
    });

    // Refresh token
    function refreshTokenHandler() {
      setInterval(async() => {
        const token = $storage.get('auth/refreshingToken');
        const request = { 'refresh_token': token };
        await refreshToken(request);
      }, 14 * 60 * 1000); // 14 min.
    }

    return { isLoggedIn };
  },
};
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & > * {
    height: inherit;
  }
}
</style>
