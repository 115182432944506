<template>
  <TheDatePicker
    :dates="{ ...dates }"
    @date-selected="setDates($event)"
  />
</template>

<script>
import { storeToRefs } from 'pinia';
import TheDatePicker from '@/app/ui/TheDatePicker.vue';
import { useDashboardStore } from '@/dashboard/store';

export default {
  components: {
    TheDatePicker,
  },
  setup() {
    // Store
    const store = useDashboardStore();
    const { dates } = storeToRefs(store);

    // Set dates in store
    function setDates(val) {
      dates.value.start = val.start;
      dates.value.end = val.end;
    }

    return {
      dates,
      setDates,
    };
  },
};
</script>
