import Vue from 'vue';
import $i18n from '@/i18n';
import VueRouter from 'vue-router';
import TheSidebar from '@/app/ui/TheSidebar.vue';
import DashboardPage from '@/dashboard/ui/DashboardPage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: DashboardPage,
    components: {
      default: DashboardPage,
      TheSidebar,
    },
    props: { mainMenu: true },
    meta: { title: $i18n.t('dashboard') },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '@/login/ui/LoginPage.vue'),
    meta: { title: $i18n.t('login') },
  },
  {
    path: '/rules',
    name: 'rules',
    components: {
      default: () => import(/* webpackChunkName: "rules" */ '@/rules/ui/RulesPage.vue'),
      TheSidebar,
    },
    props: { mainMenu: true },
    meta: { title: $i18n.t('rules') },
  },
  {
    path: '/reports',
    name: 'reports',
    components: {
      default: () => import(/* webpackChunkName: "reports" */ '@/reports/ui/ReportsPage.vue'),
      TheSidebar,
    },
    props: { mainMenu: true },
    meta: { title: $i18n.t('reports') },
  },
  {
    path: '/settings',
    name: 'settings',
    components: {
      default: () => import(/* webpackChunkName: "settings" */ '@/settings/ui/SettingsPage.vue'),
      TheSidebar,
    },
    props: { mainMenu: true },
    meta: { title: $i18n.t('settings') },
  },
  {
    path: '/log-out',
    name: 'log-out',
    props: { mainMenu: true },
    beforeEnter: () => Vue.$storage.clear(),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = !!Vue.$storage.get('auth/token');

  if (to.name !== 'login' && !isLoggedIn || to.name === 'log-out') {
    next({ name: 'login' });
  } else {
    const title = to.meta.title ? `Codenotary ACM: ${ to.meta.title }` : 'Codenotary ACM';
    window.document.title = title;
    next();
  }
});

export default router;
