<template>
  <div class="flex gap-2">
    <label class="flex flex-1 items-center relative">
      <DashboardTooltip
        class="absolute right-1"
        content-class="-top-12 right-6"
      >
        <template #activator>
          <HelpCircleOutlineIcon />
        </template>
        <div class="bg-primary-dark drop-shadow-lg p-3 rounded-lg flex">
          <HelpCircleOutlineIcon
            :size="18"
            class="pl-1 pr-2"
          />
          <div class="flex flex-col gap-1">
            <div>{{ $t('object-dashboard-search-info.title') }}</div>
            <div
              v-for="text in $t('object-dashboard-search-info.content')"
              :key="text"
              class="whitespace-nowrap"
            >
              {{ text }}
            </div>
          </div>
        </div>
      </DashboardTooltip>
      <input
        v-model="input"
        type="search"
        class="bg-primary-dark py-2.5 text-xs text-light px-3.75 pr-8 leading-4.5 w-full rounded outline-light focus:outline-secondary"
        :placeholder="$t('object-dashboard-search-info.content[0]')"
      >
    </label>
  </div>
</template>

<script>
import {
  ref,
  computed,
} from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import HelpCircleOutlineIcon from 'vue-material-design-icons/HelpCircleOutline.vue';
import DashboardTooltip from '@/dashboard/ui/DashboardTooltip';
import { useDashboardStore } from '@/dashboard/store';

export default {
  components: {
    HelpCircleOutlineIcon,
    DashboardTooltip,
  },
  setup() {
    // Store
    const store = useDashboardStore();
    const { searchString } = storeToRefs(store);

    const timeout = ref(null);
    const input = computed({
      get() {
        return searchString.value;
      },
      set(val) {
        if (timeout.value) {
          clearTimeout(timeout.value);
        }
        timeout.value = setTimeout(() => searchString.value = val, 800);
      },
    });

    return { input };
  },
};
</script>
