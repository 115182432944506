import axios from 'axios';
import storage from 'store2';

const token = storage.get('auth/token');

export const API_CONFIG = {
  headers: {
    common: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Accept: 'application/json',
      Authorization: `Bearer ${ token }`,
    },
  },
  returnRejectedPromiseOnError: true,
  timeout: 30000,
};

export const ApiService = axios.create({
  ...API_CONFIG,
  baseURL: process.env.VUE_APP_API_URL,
});

ApiService.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error?.response?.status == 401 && !window.location.pathname.includes('login')) {
    storage.remove('auth/token');
    window.location.href = '/';
  }

  return Promise.reject(error);
});
