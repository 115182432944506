<template>
  <div>
    <h2>Coming soon..</h2>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: Object, default: () => ({}) },
    data: { type: Object, default: () => ({}) },
  },
};
</script>
