<template>
  <ul class="flex gap-2 items-center">
    <li>
      <button
        class="flex items-center"
        :class="isInFirstPage ? 'text-grey' : 'text-light'"
        type="button"
        :disabled="isInFirstPage"
        @click="changePage(1)"
      >
        <ChevronDoubleLeft :size="16" />
        {{ $t('first') }}
      </button>
    </li>
    <li>
      <button
        class="flex items-center"
        :class="isInFirstPage ? 'text-grey' : 'text-light'"
        type="button"
        :disabled="isInFirstPage"
        @click="changePage(currentPage - 1)"
      >
        <ChevronLeft :size="16" />
        {{ $t('previous') }}
      </button>
    </li>
    <li
      v-for="page in pages"
      :key="page.name"
    >
      <button
        :class="page.isDisabled ? 'text-secondary font-bold' : 'text-light'"
        type="button"
        :disabled="page.isDisabled"
        @click="changePage(page.name)"
      >
        {{ page.name }}
      </button>
    </li>
    <li>
      <button
        class="flex items-center"
        :class="isInLastPage ? 'text-grey' : 'text-light'"
        type="button"
        :disabled="isInLastPage"
        @click="changePage(currentPage + 1)"
      >
        {{ $t('next') }}
        <ChevronRight :size="16" />
      </button>
    </li>
    <li>
      <button
        class="flex items-center"
        :class="isInLastPage ? 'text-grey' : 'text-light'"
        type="button"
        :disabled="isInLastPage"
        @click="changePage(totalPages)"
      >
        {{ $t('last') }}
        <ChevronDoubleRight :size="16" />
      </button>
    </li>
  </ul>
</template>

<script>
import { computed } from '@vue/composition-api';
import ChevronDoubleLeft from 'vue-material-design-icons/ChevronDoubleLeft.vue';
import ChevronDoubleRight from 'vue-material-design-icons/ChevronDoubleRight.vue';
import ChevronLeft from 'vue-material-design-icons/ChevronLeft.vue';
import ChevronRight from 'vue-material-design-icons/ChevronRight.vue';
import { useDashboardStore } from '@/dashboard/store';

export default {
  components: {
    ChevronLeft,
    ChevronRight,
    ChevronDoubleLeft,
    ChevronDoubleRight,
  },
  props: {
    maxVisibleButtons: { type: Number, default: 3 },
    totalPages: { type: Number, required: true },
    perPage: { type: Number, required: true },
    currentPage: { type: Number, required: true },
  },
  setup(props) {
    const store = useDashboardStore();

    const isInFirstPage = computed(() => props.currentPage === 1);
    const isInLastPage = computed(() => props.currentPage === props.totalPages);

    const startPage = computed(() => {
      if (props.currentPage === 1) {
        return 1;
      }

      if (props.currentPage === props.totalPages) {
        return props.totalPages - props.maxVisibleButtons + 1;
      }

      return props.currentPage - 1;
    });

    const pages = computed(() => {
      const range = [];
      const endPage = computed(() => Math.min(startPage.value + props.maxVisibleButtons - 1, props.totalPages));

      for (let i = startPage.value; i <= endPage.value; i+= 1 ) {
        if (i > 0) {
          range.push({ name: i, isDisabled: i === props.currentPage });
        }
      }

      return range;
    });

    function changePage(page) {
      store.page = page;
    }

    return {
      isInFirstPage,
      isInLastPage,
      startPage,
      pages,
      changePage,
    };
  },
};
</script>
