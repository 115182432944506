<template>
  <div>
    <div
      v-for="(message, index) in messages"
      :key="index"
    >
      <span class="text-secondary">{{ index + 1 }})</span>
      {{ getKey(message) }}: {{ getTrimmedMessage(message) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    messages: { type: Array, required: true },
  },
  setup() {
    function getTrimmedMessage(obj) {
      const message = Object.values(obj)[0];
      const maxCharacterCount = 1000;

      return message.length > maxCharacterCount
        ? `${ message.slice(0, maxCharacterCount) }...`
        : message;
    }

    function getKey(obj) {
      return Object.keys(obj)[0];
    }

    return {
      getTrimmedMessage,
      getKey,
    };
  },
};
</script>
