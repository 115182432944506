<template>
  <div class="flex gap-2">
    <span>{{ $t('number-of-items-per-page') }}</span>
    <label
      v-for="option in options"
      :key="option"
      :class="[
        options.length > 1 ? 'cursor-pointer' : 'text-secondary cursor-default font-bold',
        option === selected ? 'text-secondary cursor-default font-bold' : 'cursor-pointer',
      ]"
    >
      <input
        v-model="store.itemsPerPage"
        class="hidden"
        type="radio"
        :value="option"
      >
      {{ option }}
    </label>
  </div>
</template>

<script>
import { useDashboardStore } from '@/dashboard/store';

export default {
  props: {
    options: { type: Array, default: () => [] },
    selected: { type: Number, default: 0 },
  },
  setup() {
    const store = useDashboardStore();

    return { store };
  },
};
</script>
