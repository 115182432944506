<template>
  <ve-table
    class="dashboard-card-table"
    :class="{ 'dashboard-card-table-overflow': !inModal }"
    :fixed-header="items.length > popupDefaultAmountItems"
    :style="{ 'word-break': 'break-word' }"
    :max-height="items.length > popupDefaultAmountItems ? 300 : 'none'"
    :columns="columns"
    :table-data="items"
    :sort-option="sortOption"
    :cell-style-option="cellStyleOption"
  />
</template>

<script>
import {
  ref,
  computed,
} from '@vue/composition-api';
import { CHANGE_TYPE_DIRECTION } from '@/dashboard/store/constants';
import DashboardCardTooltip from '@/dashboard/ui/DashboardCardTooltip.vue';
import DashboardModalMessage from '@/dashboard/ui/DashboardModalMessage.vue';
import TrendingDownIcon from 'vue-material-design-icons/TrendingDown.vue';
import TrendingUpIcon from 'vue-material-design-icons/TrendingUp.vue';

export default {
  props: {
    data: { type: Object, default: () => ({}) },
    size: { type: Number, default: Infinity },
    inModal: { type: Boolean, default: false },
  },
  setup(props, { root: { $i18n } }) {

    const popupDefaultAmountItems = ref(5);

    const sortParams = ref({
      key: '',
      val: '',
    });

    const cellStyleOption = {
      bodyCellClass: ({ row: { message } }) => {
        if (props.inModal) {
          return '';
        }

        return message ? 'overflow-visible-important' : '';
      },
    };

    const sortOption = {
      sortChange: (params) => {
        const k = Object.keys(params).find((key) => params[key] !== '');
        const v = params[k];

        sortParams.value = {
          key: k,
          val: v,
        };
      },
    };

    const columns = [
      {
        field: 'changeTime',
        title: $i18n.t('time'),
        key: 'a',
        align: 'center',
        sortBy: 'asc',
        renderBodyCell: renderChangeTime,
        renderHeaderCell: renderHeaderCell,
      },
      {
        field: 'changeTypeName',
        title: $i18n.t('change-description'),
        key: 'b',
        align: 'center',
        sortBy: '',
        renderBodyCell: renderChangeType,
        renderHeaderCell: renderHeaderCell,
      },
      {
        field: 'oldValue',
        title: $i18n.t('old-value'),
        key: 'c',
        align: 'center',
        sortBy: '',
        renderHeaderCell: renderHeaderCell,
        renderBodyCell: renderOldValue,
      },
      {
        field: 'newValue',
        title: $i18n.t('new-value'),
        key: 'd',
        align: 'center',
        sortBy: '',
        renderHeaderCell: renderHeaderCell,
        renderBodyCell: renderNewValue,
      },
      {
        field: 'additionalInformations',
        title: $i18n.t('message'),
        key: 'e',
        align: props.inModal ? 'left' : 'center',
        width: props.inModal ? '20%' : '',
        renderBodyCell: renderMessage,
        renderHeaderCell: renderHeaderCell,
      },
    ];

    if (props.inModal) {
      columns.unshift({
        field: 'rowId',
        title: '#',
        key: 'f',
        align: 'center',
        sortBy: '',
      });

      columns.splice(3, 0, {
        field: 'initiator',
        title: $i18n.t('initiator'),
        key: 'g',
        align: 'center',
        sortBy: '',
        width: '10%',
      });
    }

    const items = computed(() => {
      const changes = [...props.data.changes];
      const { key, val } = { ...sortParams.value };

      changes.forEach((change, index) => {
        const { changeType, additionalInformations } = { ...change };
        const additionalInfo = change.additionalInformations.reduce((acc, cur) => (acc = {...acc, ...cur}, acc), {});
        const hasDisappeared = change.oldValue === change.moid;
        const isDeleted = !change.newValue;
        const isChanged = change.newValue && change.oldValue;
        const statusCrud = isChanged
          ? 'Changed'
          : isDeleted
            ? 'Deleted'
            : 'Added';

        // Assign trens icons
        switch (change.changeType) {
        case 'vmLife':
        case 'hostLife':
        case 'stateTransition':
          change.changeType =  `${ change.changeType }${ statusCrud }`;
          change.oldValue = change.additionalInformations[0].type === 'vmCreated' ? additionalInfo?.oldHostName : change.oldValue;
          change.newValue = change.additionalInformations[0].type === 'vmCreated' ? additionalInfo?.newHostName : change.newValue;
          break;
        case 'vmCluster': {
          change.changeType =  hasDisappeared ? 'vmClusterDisappears' : 'vmClusterAppears';
          change.changeTypeDirection = hasDisappeared
            ? CHANGE_TYPE_DIRECTION.down
            : CHANGE_TYPE_DIRECTION.up;
          change.oldValue = additionalInfo?.oldClusterName || change.oldValue;
          change.newValue = additionalInfo?.newClusterName || change.newValue;
          break;
        }
        case 'vmHost': {
          change.changeType = hasDisappeared ? 'vmHostDisappears' : 'vmHostAppears';
          change.changeTypeDirection = hasDisappeared
            ? CHANGE_TYPE_DIRECTION.down
            : CHANGE_TYPE_DIRECTION.up;
          change.oldValue = additionalInfo?.oldHostName || change.oldValue;
          change.newValue = additionalInfo?.newHostName || change.newValue;
          break;
        }
        default: {
          const noDirection = change.newValue === change.oldValue || isNaN(change.oldValue) || isNaN(change.newValue);
          change.changeTypeDirection = noDirection
            ? null
            : change.newValue > change.oldValue
              ? CHANGE_TYPE_DIRECTION.up
              : CHANGE_TYPE_DIRECTION.down;
        }}

        // Assign details
        change.changeTypeName = $i18n.t(`object-change-types-cluster.${ changeType }`);
        change.initiator = change.additionalInformations.filter((info) => info.username)[0]?.username;
        change.rowId = index + 1;
        change.message = additionalInfo && Object.keys(additionalInfo).length
          ? JSON.stringify(additionalInfo, null, 2)
          : '';

        // Cluster OR State transition type
        if (changeType === 'clusterTransition' || changeType === 'stateTransition' || changeType === 'stateTransitionDeleted') {
          change.additionalInformations?.forEach((info) => {
            if (info.type) {
              change.changeTypeName = $i18n.t(`object-change-types-cluster.${ info.type }`);
            }
          });

          return;
        }

        // VM change OR VM cloned type
        if (changeType === 'vmChange' || changeType === 'VMCloned') {
          const news = additionalInformations.filter((item) => item.newHostName);
          const olds = additionalInformations.filter((item) => item.oldHostName);

          if (news.length && olds.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.vmHost');

            return;
          } else if (news.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.vmLifeCreated');

            return;
          } else if (olds.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.vmLifeDeleted');

            return;
          }

          const newsClusters = additionalInformations.filter((item) => item.newClusterName);
          const oldsClusters = additionalInformations.filter((item) => item.oldClusterName);
          if (newsClusters.length && oldsClusters.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.vmCluster');

            return;
          } else if (newsClusters.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.vmClusterAppears');

            return;
          } else if (oldsClusters.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.vmClusterDisappears');

            return;
          }

          return;
        }

        // Host change type
        if (changeType === 'hostChange') {
          const news = additionalInformations.filter((item) => item.newHostName || item.newClusterName);
          const olds = additionalInformations.filter((item) => item.oldHostName || item.oldClusterName);

          if (news.length && olds.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.hostTransition');

            return;
          } else if (news.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.hostLifeAdded');

            return;
          } else if (olds.length) {
            change.changeTypeName = $i18n.t('object-change-types-cluster.hostDeleted');

            return;
          }

          return;
        }

        // Host transition type
        if (changeType == 'hostTransition') {
          const news = additionalInformations.filter((item) => item.newHostName);
          const olds = additionalInformations.filter((item) => item.oldHostName);

          if (news.length && olds.length) {
            change.changeTypeName = $i18n.t('object-change-types-host.vmHost');

            return;
          } else if (news.length) {
            change.changeTypeName = $i18n.t('object-change-types-host.vmLifeCreated');

            return;
          } else if (olds.length) {
            change.changeTypeName = $i18n.t('object-change-types-host.vmLifeDeleted');

            return;
          }
        }
      });

      changes.sort((a, b) => sortAlphaNum(a[key], b[key], val));

      return changes.slice(0, props.size);
    });

    // Sort alpha-numeric ASC
    function sortAlphaNum(a, b, sortBy) {
      if (a == null) {
        return 1;
      }

      if (b == null) {
        return -1;
      }

      const regexAlphaExp = /[^a-zA-Z]/g;
      const regexNumExp = /[^0-9]/g;
      const aInt = parseInt(a, 10);
      const bInt = parseInt(b, 10);
      const orderNum1 = sortBy === 'desc' ? -1 : 1;
      const orderNum2 = sortBy === 'desc' ? 1 : -1;

      if (isNaN(aInt) && isNaN(bInt)) {
        const aAlpha = a.replace(regexAlphaExp, '');
        const bAlpha = b.replace(regexAlphaExp, '');

        if (aAlpha === bAlpha) {
          const aNum = parseInt(a.replace(regexNumExp, ''), 10);
          const bNum = parseInt(b.replace(regexNumExp, ''), 10);

          return aNum === bNum ? 0 : aNum > bNum ? orderNum1 : orderNum2;
        }

        if (sortBy === 'desc') {
          return aAlpha > bAlpha ? orderNum1 : orderNum2;
        }

        return aAlpha > bAlpha ? orderNum1 : orderNum2;
      }

      if (isNaN(aInt)) {
        return orderNum1;
      }

      if (isNaN(bInt)) {
        return orderNum2;
      }

      return aInt > bInt ? orderNum1 : orderNum2;
    }

    // Get value
    function getValue(row, type) {
      const fallbackValue = `${ type }Value`;
      const changeTypesToMap = [
        'vmChange',
        'hostChange',
        'VMCloned',
        'clusterTransition',
        'hostTransition',
        'stateTransition',
        'stateTransitionDeleted',
      ];
      const changeTypesToCut = [
        'distributedPortGroup',
        'networkName',
        'hostPortGroup',
        'networkChanges',
      ];

      if (!props.inModal && changeTypesToCut.includes(row.changeType)) {
        const splittedValues = row[fallbackValue].split(',');

        if (splittedValues.length > 1) {
          return `${ splittedValues[0].slice(0, 6) }... +${ splittedValues.length - 1 } Others`;
        }

        return row[fallbackValue];
      }

      if (!(changeTypesToMap.includes(row.changeType))) {
        return row[fallbackValue];
      }

      if (row.additionalInformations) {
        const toFindType = [`${ type }ClusterName`, `${ type }HostName`];
        const clusterDetail = row.additionalInformations.filter(
          (info) => Object.keys(info).some((key) => toFindType.includes(key)),
        )?.[0];

        return (clusterDetail && clusterDetail[Object.keys(clusterDetail).find((key) => toFindType.includes(key))])
            || row[`${ type }Value`];
      }
    }

    // Render change time
    function renderChangeTime({ row: { changeTime } }, h) {
      const raw = new Date(changeTime);
      const date = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(raw);
      const time = new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(raw);

      return h(
        'div',
        [h('div', date), h('div', time)],
      );
    }

    // Render change type
    function renderChangeType({ row: { changeTypeName, changeTypeDirection } }, h) {
      let icon = null;
      switch (changeTypeDirection) {
      case CHANGE_TYPE_DIRECTION.up:
        icon = h(
          TrendingUpIcon,
          { class: 'text-primary-light', props: { size: 16 } },
        );
        break;
      case CHANGE_TYPE_DIRECTION.down:
        icon = h(
          TrendingDownIcon,
          { class: 'text-accent-light', props: { size: 16 } },
        );
        break;
      }

      return h(
        'div',
        { class: 'flex gap-1 justify-center items-center' },
        [h('span', changeTypeName), icon],
      );
    }

    // Render old value
    function renderOldValue({ row }) {
      return getValue(row, 'old');
    }

    // Render new value
    function renderNewValue({ row }) {
      return getValue(row, 'new');
    }

    // Render header cell
    function renderHeaderCell({ column: { title } }) {
      return title;
    }

    // Render message
    function renderMessage({ row: { message } }, h) {
      if (!message) {
        return '';
      }

      if (props.inModal) {
        const messageList = [];
        const parsedMessage = JSON.parse(message);

        Object.keys(parsedMessage).forEach((messageKey) => {
          messageList.push({
            [messageKey]: parsedMessage[messageKey],
          });
        });

        return h(
          DashboardModalMessage,
          { props: {
            messages: messageList,
          }},
        );
      }

      return h(
        'div',
        { class: 'flex items-center justify-center' },
        [
          h(
            DashboardCardTooltip,
            { props: { message } },
          ),
        ],
      );
    }

    return {
      items,
      columns,
      sortOption,
      sortParams,
      popupDefaultAmountItems,
      cellStyleOption,
    };
  },
};
</script>

<style lang="scss">
.modal .ve-table .ve-table-container table.ve-table-content thead.ve-table-header tr.ve-table-header-tr th.ve-table-header-th {
  background-color: var(--primary-darker);
}

.dashboard-card-table-overflow {
  &.ve-table {
    overflow: visible;

    .ve-table-container {
      overflow: visible;
    }
  }
}

.overflow-visible-important {
  overflow: visible !important;
}

.dashboard-card .ve-table .ve-table-container table.ve-table-content thead.ve-table-header .ve-table-header-tr .ve-table-header-th .ve-table-sort {
  display: none;
}

table.ve-table-content {
  width: 100%;
}
</style>
