var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('notifications',{staticClass:"m-4",scopedSlots:_vm._u([{key:"body",fn:function(ref){
var item = ref.item;
var close = ref.close;
return [_c('div',{staticClass:"my-notification | top-4 right-4 mb-2 rounded shadow cursor-pointer",class:[
        {'bg-primary': item.type === 'success'},
        {'bg-secondary': item.type === 'warning'},
        {'bg-accent': item.type === 'error'},
        'bg-blue text-white' ],on:{"click":close}},[_c('div',{staticClass:"relative flex items-center w-full max-w-xs p-4"},[_c('div',{staticClass:"inline-flex items-center justify-center flex-shrink-0 w-8 h-8 bg-white rounded"},[(item.type === 'success')?_c('CheckCircleIcon',{staticClass:"text-grey-dark",attrs:{"size":20}}):_c('AlertCircleIcon',{staticClass:"text-grey-dark",attrs:{"size":20}})],1),_c('div',{staticClass:"ml-3 text-sm"},[_c('p',{staticClass:"title font-bold"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"msg"},[_vm._v(" "+_vm._s(item.text)+" ")])])])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }