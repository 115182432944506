<template>
  <div>
    <div class="flex justify-between pr-6">
      <p class="flex items-center gap-2">
        <component
          :is="icon"
          :size="13"
        />
        {{ host }}
        <ChevronRightIcon />
        {{ data.name }}
      </p>
      <span class="bg-secondary text-dark rounded-xl px-4 lowercase">
        {{ $t('number-of-changes') }}: {{ numberOfChanges }}
      </span>
    </div>
    <div class="flex justify-between items-top py-11">
      <div
        :class="{ 'w-52': cpuChanges.length && vmChanges.length }"
        class="text-center"
      >
        <h4 class="mb-4">
          {{ $t('summary') }}
        </h4>
        <p class="text-sm">
          {{ $t('start-to-end', { start, end }) }}
        </p>
      </div>
      <DashboardModalSummaryStats
        :items="cpuChanges"
        arrows-on
      />
      <DashboardModalSummaryStats
        :items="vmChanges"
      />
    </div>
  </div>
</template>

<script>
import ChevronRightIcon from 'vue-material-design-icons/ChevronRight.vue';
import GoogleCirclesExtendedIcon from 'vue-material-design-icons/GoogleCirclesExtended';
import CubeScanIcon from 'vue-material-design-icons/CubeScan';
import ServerIcon from 'vue-material-design-icons/Server.vue';
import { computed } from '@vue/composition-api';
import { useDashboardStore } from '@/dashboard/store';
import DashboardModalSummaryStats from '@/dashboard/ui/DashboardModalSummaryStats.vue';

export default {
  components: {
    DashboardModalSummaryStats,
    ChevronRightIcon,
    GoogleCirclesExtendedIcon,
    ServerIcon,
    CubeScanIcon,
  },
  props: {
    data: { type: Object, default: () => ({}) },
    icon: { type: String, default: 'GoogleCirclesExtendedIcon' },
    numberOfChanges: { type: Number, default: 0 },
    name: { type: String, default: '' },
    start: { type: String, default: '' },
    end: { type: String, default: '' },
    summary: { type: Object, default: () => ({}) },
  },
  setup(props) {
    const store = useDashboardStore();
    const host = computed(() => {
      const item = store.vcenters.find((el) => el.uuid === props.data.vcenter_uuid);

      return item.host;
    });

    const cpuChangeTypes = [
      'coresSummary',
      'cpuSpeedSummary',
      'memorySummary',
      'socketsSummary',
    ];
    const vmChangeTypes = [
      'hostDistributedPortgroupSummary',
      'hostPortgroupSummary',
      'networksSummary',
      'vmsClonedSummary',
      'vmsSummary',
    ];
    const vmChanges = [];
    const cpuChanges = [];

    Object.keys(props.summary).forEach((item) => {
      if (!props.summary[item]) {
        return;
      }

      if (cpuChangeTypes.includes(item)) {
        if (item === 'memorySummary') {
          cpuChanges.push({
            title: item,
            oldValue: props.summary[item].oldValue !== 0 ? (props.summary[item].oldValue / 1024).toFixed(0) : '',
            newValue: props.summary[item].newValue !== 0 ? (props.summary[item].newValue / 1024).toFixed(0) : '',
          });

          return;
        }

        if (item === 'cpuSpeedSummary') {
          cpuChanges.push({
            title: item,
            oldValue: props.summary[item].oldValue !== 0 ? (props.summary[item].oldValue / 1000).toFixed(3) : '',
            newValue: props.summary[item].newValue !== 0 ? (props.summary[item].newValue / 1000).toFixed(3) : '',
          });

          return;
        }

        cpuChanges.push({
          title: item,
          oldValue: props.summary[item].oldValue === 0 ? '' : props.summary[item].oldValue,
          newValue: props.summary[item].newValue === 0 ? '' : props.summary[item].newValue,
        });
      }

      if (vmChangeTypes.includes(item)) {
        vmChanges.push({
          title: item,
          oldValue: props.summary[item].minus === 0 ? '' : `-${ props.summary[item].minus }`,
          newValue: props.summary[item].plus === 0 ? '' : `+${ props.summary[item].plus }`,
        });
      }
    });

    return {
      host,
      vmChanges,
      cpuChanges,
    };
  },
};
</script>
