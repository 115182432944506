import { ApiService } from '@/app/services/index';

export const LdapService = {
  checkConnection(data) {
    return ApiService.post('/ldap/connectivity/check', data);
  },
  getGroups(data) {
    return ApiService.post('/ldap/groups/list', {
      invalidateCache: false,
      prefix: '',
      port: 636,
      use_tls: true,
      server: data.server,
      user: data.user,
      password: data.password,
    });
  },
  getSettings() {
    return ApiService.get('/settings/ldap/get');
  },
  updateSettings(data) {
    return ApiService.put('/settings/ldap/update', data);
  },
};
