<template>
  <div class="flex-1 flex flex-col gap-4 mt-4">
    <DashboardSearch />
    <div class="flex gap-4 flex-1">
      <div class="flex flex-col gap-4 item-stretch w-64">
        <DashboardCardTypePicker />
        <DashboardDatePicker />
        <DashboardResponseIncludes />
      </div>
      <div class="relative flex-1">
        <div
          v-if="settings.itemsNotFound"
          class="flex flex-1 h-1/2 items-center justify-center text-grey"
        >
          {{ $t('items-not-found') }}
        </div>
        <div
          v-if="settings.loading"
          class="flex h-96 items-center justify-center"
        >
          <BaseLoader
            class="h-8 w-8"
            :loading="settings.loading"
            :size="36"
          />
        </div>
        <div
          v-else
          class="gap-4"
          :class="settings.isViewExtended ? 'flex flex-col' : 'grid grid-cols-2'"
        >
          <DashboardCard
            v-for="item in settings.changes"
            :key="item.id"
            :data="item"
            :size="params.size"
            :is-extended="settings.isViewExtended"
            icon="ServerIcon"
            @click.native="selectedCard = item"
          />
          <portal
            v-if="selectedCard"
            to="body"
          >
            <BaseModal @close="selectedCard = false">
              <template #header>
                <DashboardModalSummary
                  icon="ServerIcon"
                  :data="selectedCard"
                  :number-of-changes="selectedCard.changes.length"
                  :start="new Date(params.fromWhen).toUTCString()"
                  :end="new Date(params.toWhen).toUTCString()"
                  :summary="selectedCard.summary"
                />
              </template>
              <DashboardCardTable
                :data="selectedCard"
                :in-modal="true"
                class="flex-1 bg-primary-darker max-h-80 rounded-lg"
              />
            </BaseModal>
          </portal>
        </div>
      </div>
    </div>
    <DashboardPaginator />
  </div>
</template>

<script>
import {
  ref,
  watchEffect,
} from '@vue/composition-api';
import BaseModal from '@/app/ui/BaseModal';
import BaseLoader from '@/app/ui/BaseLoader.vue';
import DashboardCardTypePicker from '@/dashboard/ui/DashboardCardTypePicker.vue';
import DashboardDatePicker from '@/dashboard/ui/DashboardDatePicker.vue';
import DashboardModalSummary from '@/dashboard/ui/DashboardModalSummary';
import DashboardCard from '@/dashboard/ui/DashboardCard.vue';
import DashboardCardTable from '@/dashboard/ui/DashboardCardTable.vue';
import DashboardResponseIncludes from '@/dashboard/ui/DashboardResponseIncludes.vue';
import DashboardPaginator from '@/dashboard/ui/DashboardPaginator.vue';
import DashboardSearch from '@/dashboard/ui/DashboardSearch.vue';
import { useDashboardStore } from '@/dashboard/store';
import { GET_HOST_CHANGES } from '@/dashboard/store/constants';

export default {
  components: {
    BaseModal,
    BaseLoader,
    DashboardModalSummary,
    DashboardCard,
    DashboardCardTable,
    DashboardSearch,
    DashboardPaginator,
    DashboardCardTypePicker,
    DashboardResponseIncludes,
    DashboardDatePicker,
  },
  props: {
    params: { type: Object, default: () => ({}) },
    settings: { type: Object, default: () => ({}) },
  },
  setup(props) {
    // Store
    const store = useDashboardStore();
    const getHostChanges = store[GET_HOST_CHANGES];

    // Watcher
    watchEffect(async() => await getHostChanges(props.params));

    // Select card to show in modal
    const selectedCard = ref(null);

    return { selectedCard };
  },
};
</script>
