<template>
  <aside
    class="the-sidebar | bg-primary-dark text-grey-light w-24 hover:w-44 fixed z-50 transition-all"
    :class="{ 'the-sidebar--open': showSidebarOnMobile }"
  >
    <div
      class="the-sidebar__inner | bg-primary-dark h-full fixed z-10 px-5 py-7.5 hover:shadow-md transition-all duration-700"
      :class="{ 'the-sidebar__inner--show': showSidebarOnMobile }"
    >
      <TheSidebarHeader />
      <TheSidebarNav @focusin.native="showSidebarOnMobile = false" />
    </div>
    <TheSidebarFloatButton
      :button-pressed="showSidebarOnMobile"
      @click.native="showSidebarOnMobile = !showSidebarOnMobile"
    />
  </aside>
</template>

<script>
import { ref } from '@vue/composition-api';
import TheSidebarHeader from '@/app/ui/TheSidebarHeader.vue';
import TheSidebarNav from '@/app/ui/TheSidebarNav.vue';
import TheSidebarFloatButton from '@/app/ui/TheSidebarFloatButton.vue';

export default {
  components: {
    TheSidebarHeader,
    TheSidebarNav,
    TheSidebarFloatButton,
  },
  setup() {
    const showSidebarOnMobile = ref(false);

    return { showSidebarOnMobile };
  },
};
</script>

<style lang="scss" scoped>
.the-sidebar {

  &--open {

    &:after {
      background-color: theme('colors.black');
      content: "";
      height: 100%;
      width: 100%;
      position: fixed;
      z-index: 3;
      animation: 1s ease 0s normal forwards 1 fadein;
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: calc(100% - 65px);
    transform: translateX(-100%);

    &--show {
      transform: translateX(0);
    }
  }

  @media (min-width: theme('screens.md')) {
    &--open {

      &:after {
        display: none;
      }
    }

    // min-width: theme('width.the-sidebar');
    // width: theme('width.the-sidebar');

    &__inner {
      width: inherit;
      transform: translateX(0);
    }
  }

  @keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: .6; }
  }
}
</style>
