export function objectToQueryString(params) {
  if (!params || !Object.entries(params).length) {
    return '';
  }

  return Object
    .entries(params)
    .flatMap(([key, value]) => {
      if (value || typeof value === 'boolean') {
        const encodedKey = `&${ encodeURIComponent(key) }`;

        if (Array.isArray(value)) {
          return value.map((el) => `${ encodedKey }=${ encodeURIComponent(el) }`);
        }

        if (value instanceof Date) {
          return `${ encodedKey }=${ encodeURIComponent(value.toISOString()) }`;
        }

        return `${ encodedKey }=${ encodeURIComponent(value) }`;
      }
    })
    .join('')
    .replace('&', '?');
}
