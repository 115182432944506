<template>
  <div class="flex gap-4">
    <button
      class="p-2 font-bold flex gap-2 flex-1 rounded-lg transition-colors"
      :class="getClassNames(!isViewExtended)"
      @click="isViewExtended = false"
    >
      <ViewGridOutlineIcon />
      {{ $t('compact') }}
    </button>
    <button
      class="p-2 font-bold flex gap-2 flex-1 rounded-lg transition-colors"
      :class="getClassNames(isViewExtended)"
      @click="isViewExtended = true"
    >
      <ViewAgendaOutlineIcon />
      {{ $t('extended') }}
    </button>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { storeToRefs } from 'pinia';
import ViewGridOutlineIcon from 'vue-material-design-icons/ViewGridOutline.vue';
import ViewAgendaOutlineIcon from 'vue-material-design-icons/ViewAgendaOutline.vue';
import { useDashboardStore } from '@/dashboard/store';

export default {
  components: {
    ViewGridOutlineIcon,
    ViewAgendaOutlineIcon,
  },
  setup() {
    // Store
    const store = useDashboardStore();
    const { isViewExtended } = storeToRefs(store);

    // Get tailwind classes for buttons
    function getClassNames(val) {
      const isDisabled = computed(() => !store.changes.length);

      return [
        isDisabled.value ? 'opacity-70' : '', val
          ? 'bg-secondary text-primary-dark cursor-default hover:bg-secondary'
          : 'bg-primary-dark text-white',
      ];
    }

    return {
      isViewExtended,
      getClassNames,
    };
  },
};
</script>
