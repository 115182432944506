<template>
  <notifications class="m-4">
    <template
      slot="body"
      slot-scope="{ item, close }"
    >
      <div
        class="my-notification | top-4 right-4 mb-2 rounded shadow cursor-pointer"
        :class="[
          {'bg-primary': item.type === 'success'},
          {'bg-secondary': item.type === 'warning'},
          {'bg-accent': item.type === 'error'},
          'bg-blue text-white',
        ]"
        @click="close"
      >
        <div class="relative flex items-center w-full max-w-xs p-4">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 bg-white rounded">
            <CheckCircleIcon
              v-if="item.type === 'success'"
              class="text-grey-dark"
              :size="20"
            />
            <AlertCircleIcon
              v-else
              class="text-grey-dark"
              :size="20"
            />
          </div>
          <div class="ml-3 text-sm">
            <p class="title font-bold">
              {{ item.title }}
            </p>
            <div class="msg">
              {{ item.text }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </notifications>
</template>

<script>
import AlertCircleIcon from 'vue-material-design-icons/AlertCircle.vue';
import CheckCircleIcon from 'vue-material-design-icons/CheckCircle.vue';

export default {
  components: {
    AlertCircleIcon,
    CheckCircleIcon,
  },
};
</script>
