<template>
  <div>
    <p class="text-xs text-center">
      {{ $t('alert-report-summary-section') }}
    </p>
    <div class="grid grid-cols-3 gap-7 mt-5">
      <div
        v-for="checkbox in reportModalState.checkboxes"
        :key="checkbox.text"
      >
        <BaseCheckbox
          :checked="checkbox.checked"
          class="mb-4"
          @click.native="parentCheckboxClick(checkbox)"
        >
          <span class="text-xs font-bold">{{ checkbox.text }}</span>
        </BaseCheckbox>
        <div
          v-if="checkbox.children"
          class="ml-2"
        >
          <BaseCheckbox
            v-for="childCheckbox in checkbox.children"
            :key="childCheckbox.text"
            :checked="childCheckbox.checked"
            class="mb-4"
            @click.native="childCheckbox.checked = !childCheckbox.checked"
          >
            <span class="text-xs">{{ childCheckbox.text }}</span>
          </BaseCheckbox>
        </div>
      </div>
    </div>
    <div class="grid justify-center mt-4 gap-y-4">
      <p
        v-if="reportModalState.showPageSizeError"
        class="text-xs text-accent-light flex"
      >
        <InformationIcon
          class="mr-2"
          :size="12"
        />
        {{ $t('report-page-size-error-message', { val: 20 }) }}
      </p>
      <BaseButton
        text="Create a Report"
        text-small
      />
      <p
        v-if="reportModalState.showSuccess"
        class="text-primary-light text-xs font-bold flex items-center"
      >
        <CheckIcon
          class="mr-2"
          :size="12"
        /> {{ $t('report-success-message') }}
      </p>
      <p
        v-if="reportModalState.showError"
        class="text-accent-light text-xs font-bold flex items-center"
      >
        <CloseIcon
          class="mr-2"
          :size="12"
        /> {{ $t('report-error-message') }}
      </p>
    </div>
  </div>
</template>

<script>
import CheckIcon from 'vue-material-design-icons/Check.vue';
import CloseIcon from 'vue-material-design-icons/Close.vue';
import InformationIcon from 'vue-material-design-icons/Information.vue';
import { reactive } from '@vue/composition-api';
import BaseButton from '@/app/ui/BaseButton.vue';
import BaseCheckbox from '@/app/ui/BaseCheckbox.vue';

export default {
  components: {
    BaseButton,
    BaseCheckbox,
    CheckIcon,
    CloseIcon,
    InformationIcon,
  },
  setup(props, { root: { $i18n } }) {
    const reportModalState = reactive({
      checkboxes: [
        { text: $i18n.t('clusters'), checked: true, children: [
          { text: $i18n.t('host-count'), checked: true },
          { text: $i18n.t('vsphere-version'), checked: true },
          { text: $i18n.t('distributed-switch'), checked: true },
          { text: $i18n.t('vms'), checked: true },
        ]},
        { text: $i18n.t('hosts'), checked: true, children: [
          { text: $i18n.t('cpu'), checked: true },
          { text: $i18n.t('memory'), checked: true },
          { text: $i18n.t('network'), checked: true },
          { text: $i18n.t('name'), checked: true },
          { text: $i18n.t('IP'), checked: true },
          { text: $i18n.t('NIC'), checked: true },
          { text: $i18n.t('usb-device-inserted'), checked: true },
        ]},
        { text: $i18n.t('vms'), checked: true, children: [
          { text: $i18n.t('host-location'), checked: true },
          { text: $i18n.t('cpu'), checked: true },
          { text: $i18n.t('memory'), checked: true },
          { text: $i18n.t('port-group'), checked: true },
          { text: $i18n.t('nvic'), checked: true },
          { text: $i18n.t('ip'), checked: true },
          { text: $i18n.t('name'), checked: true },
          { text: $i18n.t('device'), checked: true },
        ]},
      ],
      showError: false,
      showSuccess: false,
      showPageSizeError: false,
    });

    function parentCheckboxClick(checkbox) {
      const toMakeValue = !checkbox.checked;

      checkbox.checked = toMakeValue;

      reportModalState.checkboxes
        .find((box) => box.text === checkbox.text)
        .children.forEach((child) => {
          child.checked = toMakeValue;
        });
    }

    return {
      reportModalState,
      parentCheckboxClick,
    };
  },
};
</script>
