import { ApiService } from '@/app/services/index';

export const SystemUpdateService = {
  uploadSystemUpdateFile(data, config) {
    return ApiService.patch('/patch/patch', data, {
      timeout: 60 * 1000 * 30,
      ...config,
    });
  },
  uploadSystemProgress() {
    return ApiService.get('/patch/ispatching');
  },
  version() {
    return ApiService.get('/system/version/get');
  },
};
