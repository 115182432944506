<template>
  <div class="p-4 bg-primary-dark gap-3 rounded-lg flex flex-col items-start text-sm">
    <div class="font-bold text-center">
      {{ $t('filter-by') }}
    </div>
    <ul>
      <li
        v-for="item in responseIncludesByTab"
        :key="item.key"
        class="flex mb-3 capitalize"
      >
        <BaseToggleSwitch
          :checked="item.selected"
          @click.native="item.selected = !item.selected"
        >
          <template #after>
            <span class="pl-3">{{ item.label }}</span>
          </template>
        </BaseToggleSwitch>
      </li>
    </ul>
  </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import BaseToggleSwitch from '@/app/ui/BaseToggleSwitch.vue';
import { useDashboardStore } from '@/dashboard/store';

export default {
  components: {
    BaseToggleSwitch,
  },
  setup() {
    const store = useDashboardStore();
    const { responseIncludesByTab } = storeToRefs(store);

    return { responseIncludesByTab };
  },
};
</script>
