<template>
  <transition
    name="modal"
    mode="out-in"
  >
    <div
      class="modal | flex h-full w-full inset-0 justify-center items-center z-50 fixed"
      @click.self="$emit('close')"
    >
      <div
        class="modal__content | p-5 pr-10 rounded shadow-md fixed w-10/12"
        :class="[{ 'max-w-lg' : small }, darker ? 'bg-primary-darker' : 'bg-primary-dark']"
      >
        <button
          class="text-light absolute right-5 top-5"
          @click="$emit('close')"
        >
          <CloseCircleOutlineIcon :size="20" />
        </button>
        <div class="modal__header">
          <slot name="header" />
        </div>

        <div class="modal__body">
          <slot />
        </div>

        <div class="modal__footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  onMounted,
  onUnmounted,
} from '@vue/composition-api';
import CloseCircleOutlineIcon from 'vue-material-design-icons/CloseCircleOutline.vue';

export default {
  components: {
    CloseCircleOutlineIcon,
  },
  props: {
    small: { type: Boolean, default: false },
    darker: { type: Boolean, default: false },
  },
  setup() {
    onMounted(() => {
      window.document.body.classList.add('overflow-hidden');
    });

    onUnmounted(() => {
      window.document.body.classList.remove('overflow-hidden');
    });
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &:after {
    background: theme('colors.darker');
    display: block;
    content: '';
    top: 0;
    min-height: 100%;
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: .35;
    z-index: -1;
  }

  &__content {
    max-height: 80%;
    overflow: auto;
  }
}

.modal-enter-active {
  transition: all .3s ease-in-out;
}
.modal-leave-active {
  transition: all .3s ease-out;
}
.modal-enter,
.modal-leave-to {
  opacity: 0;
}
</style>
