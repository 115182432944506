<template>
  <div class="flex gap-2">
    <button
      v-for="tab in tabs"
      :key="tab.value"
      class="btn flex items-center text-light py-1 px-6 rounded-lg font-bold group"
      :class="tab.selected ? 'bg-brand' : 'bg-primary-dark'"
      @click="selectTab(tab)"
    >
      <component
        :is="getIcon(tab)"
        :size="19"
      />
      <span
        class="group-hover:w-auto group-hover:pl-3 overflow-hidden transition-all ease-in-out"
        :class="tab.selected ? 'w-auto pl-3' : 'w-0'"
      >
        {{ tab.label }}
      </span>
    </button>
  </div>
</template>

<script>
import ServerIcon from 'vue-material-design-icons/Server.vue';
import CubeScanIcon from 'vue-material-design-icons/CubeScan.vue';
import ChartLineIcon from 'vue-material-design-icons/ChartLine.vue';
import BellOutlineIcon from 'vue-material-design-icons/BellOutline.vue';
import PuzzleOutlineIcon from 'vue-material-design-icons/PuzzleOutline.vue';
import GoogleCirclesExtendedIcon from 'vue-material-design-icons/GoogleCirclesExtended.vue';
import { useDashboardStore } from '@/dashboard/store';

export default {
  setup() {
    const store = useDashboardStore();
    const tabs = store.tabs;

    function getIcon(tab) {
      switch(tab.value) {
      case 'cluster':
        return GoogleCirclesExtendedIcon;
      case 'host':
        return ServerIcon;
      case 'vm':
        return CubeScanIcon;
      case 'statistics':
        return ChartLineIcon;
      case 'extension':
        return PuzzleOutlineIcon;
      case 'alerts':
        return BellOutlineIcon;
      default: return;
      }
    }

    function selectTab(tab) {
      tabs.forEach((el) => el.selected = false);
      tab.selected = true;
    }

    return {
      tabs,
      getIcon,
      selectTab,
    };
  },
};

</script>
