<template>
  <p>
    <template v-if="end">
      <span class="text-secondary font-bold">{{ start }}-{{ end }}</span>
      <span class="text-secondary font-bold lowercase"> {{ $t('results') }}</span>
      <span class="text-light"> {{ $t('out-of') }}</span>
    </template>
    <span class="text-secondary font-bold"> {{ changes }}</span>
    <span class="text-light"> {{ $t('total') }}</span>
  </p>
</template>

<script>
export default {
  props: {
    start: { type: Number, default: 0 },
    end: { type: Number, default: 0 },
    changes: { type: Number, default: 0 },
  },
};
</script>
