import { defineStore } from 'pinia';
import { DASHBOARD_MODULE } from '@/dashboard/store/constants';
import actions from '@/dashboard/store/actions';
import getters from '@/dashboard/store/getters';
import state from '@/dashboard/store/state';

export const useDashboardStore = defineStore(DASHBOARD_MODULE, {
  actions,
  getters,
  state,
});
